<template>
  <v-expansion-panels class="mt-4" v-model="panel">
    <v-expansion-panel value="properties">
      <v-expansion-panel-title>
        <span class="text-h6 font-weight-medium">
          {{ $t('referential.dataset.properties.label') }}
          ({{ dataset && dataset.properties && dataset.properties.length > 0 ? dataset.properties.length : 0 }})
        </span>
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <v-card-text>
          <v-card-title>
            <v-row class="my-2">
              <v-spacer/>
              <v-btn v-if="drag" color="warning" class="mr-2" @click="saveNewOrder">
                {{ $t('referential.dataset.properties.save-new-order') }}
              </v-btn>
              <v-btn color="primary" @click="openProperty=true">
                {{ $t('referential.dataset.properties.add') }}
              </v-btn>
            </v-row>
          </v-card-title>
          <div v-if="properties && properties.length > 0">

            <v-row class="border my-1 pa-2 bg-primary" dense>
              <v-col>
                <span class="text-subtitle-1 font-weight-bold ml-6">{{ $t('common.order') }}</span>
              </v-col>
              <v-col cols="2">
                <span class="text-subtitle-1 font-weight-bold">{{ $t('common.code') }}</span>
              </v-col>
              <v-col cols="4">
                <span class="text-subtitle-1 font-weight-bold">{{ $t('common.label') }}</span>
              </v-col>
              <v-col>
                <span class="text-subtitle-1 font-weight-bold">
                  {{ $t('referential.dataset.properties.type.label') }}
                </span>
              </v-col>
              <v-col>
                <span class="text-subtitle-1 font-weight-bold">
                  {{ $t('referential.dataset.properties.filterable') }}
                </span>
              </v-col>
              <v-col>
                <span class="text-subtitle-1 font-weight-bold">
                  {{ $t('referential.dataset.properties.displayable') }}
                </span>
              </v-col>
              <v-col>
              </v-col>
            </v-row>
            <div v-for="element in properties">
              <v-row align="center" class="border pa-2 bg-grey-lighten-5" dense>
                <v-col>
                  <v-icon class="handle mr-2" size="small">mdi-drag-horizontal-variant</v-icon>
                  <span class="text-subtitle-1">{{ element.orderIndex }}</span>
                </v-col>
                <v-col cols="2">
                  <strong style="font-family: Consolas, Menlo, Courier, monospace; font-size: medium">{{ element.code }}</strong>
                </v-col>
                <v-col cols="4">
                  <span class="text-subtitle-1 font-weight-regular font-italic">{{ element.label }}</span>
                </v-col>
                <v-col>
                  <v-chip color="purple" label>
                    <span style="font-family: monospace, serif">
                      {{ $t('referential.dataset.properties.type.' + element.type.toLowerCase()) }}
                    </span>
                  </v-chip>
                </v-col>
                <v-col>
                  <v-chip color="blue" label><span style="font-family: monospace, serif">{{ element.showInFilter }}</span></v-chip>
                </v-col>
                <v-col>
                  <v-chip color="blue" label><span style="font-family: monospace, serif">{{ element.showInTable }}</span></v-chip>
                </v-col>
                <v-col class="d-flex flex-row justify-end">
                  <v-btn icon="mdi-pencil" size="small" color="primary" variant="text" @click="editProperty(element)" />
                  <v-btn icon="mdi-delete" size="small" color="error" variant="text" class="ml-2" @click="deleteProperty(element)" />
                </v-col>
              </v-row>
            </div>
          </div>
        </v-card-text>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
  <v-dialog v-model="openProperty" persistent>
    <DatasetProperty :dataset-code="dataset.code" :property="propertyToEdit" @canceled="clearPropertyDialog"
                     @saved="saveProperty" @deleted="deleteProperty" />
  </v-dialog>
</template>
<script>
import draggable from "vuedraggable";
import ButtonWithTooltip from "@/components/common/ButtonWithTooltip.vue";
import DatasetProperty from "@/components/referential/DatasetProperty.vue";
import DatasetAPI from "@/api/referential/DatasetAPI";

export default {
  name: "DatasetProperties",
  components: {DatasetProperty, ButtonWithTooltip, draggable},
  props: {
    dataset: {}
  },
  data: () => ({
    drag: false,
    panel: [],
    properties: [],
    openProperty: false,
    propertyToEdit: {
      creation: true
    }
  }),
  created() {
    this.sortProperties()
  },
  methods: {
    saveNewOrder() {
      this.drag = false;
      const newOrder = new Map();
      for (const [index, value] of this.properties.entries()) {
        newOrder.set(value.code, index + 1);
      }
      DatasetAPI.saveNewPropertiesOrder(this.dataset.code, newOrder)
          .then(saved => {
            this.dataset.properties = saved.properties
            this.sortProperties()
            this.$toast.success(this.$t('referential.dataset.properties.new-order-saved'))
          })
    },
    editProperty(property) {
      this.propertyToEdit = property;
      this.openProperty = true;
    },
    saveProperty(property) {
      let promise;
      if (property.creation) {
        promise = DatasetAPI.addProperty(this.dataset.code, this.propertyToEdit)
      } else {
        promise = DatasetAPI.updateProperty(this.dataset.code, this.propertyToEdit)
      }
      promise.then(saved => {
        this.dataset.properties = saved.properties
        this.sortProperties()
        this.$toast.success(this.$t('referential.dataset.properties.' + (property.creation ? 'created' : 'updated'), {code: property.code}))
      }).finally(() => this.clearPropertyDialog());
    },
    deleteProperty(property) {
      this.$root.$confirm(this.$t('referential.dataset.properties.delete-msg', {code: property.code}), this.$t('referential.dataset.properties.confirm-delete-msg', {code: property.code}))
          .then(confirm => {
            if (confirm) {
              DatasetAPI.deleteProperty(this.dataset.code, property.code)
                  .then(saved => {
                    this.dataset.properties = saved.properties
                    this.sortProperties()
                    this.$toast.warning(this.$t('referential.dataset.properties.deleted', {code: property.code}))
                  })
            }
          })
    },
    clearPropertyDialog() {
      this.openProperty = false;
      this.propertyToEdit = {
        creation: true
      };
    },
    sortProperties() {
      if (this.dataset && this.dataset.properties) {
        this.properties = this.dataset.properties.sort((a, b) => (a.orderIndex > b.orderIndex) ? 1 : -1)
      }
    }
  }
}
</script>