<template>
    <v-app-bar color="primary">
        <v-app-bar-title>
            <v-row class="pa-0 ma-0">
                <img src='/studio-color.png' alt="studio" width="32">
                <span class="font-weight-medium ml-2">RuleShake <span class="font-weight-light"> Studio</span></span>
            </v-row>
        </v-app-bar-title>
        <v-spacer/>
        <Docs/>
    </v-app-bar>
    <div class="d-flex flex-column align-center mt-12">
        <v-row>
            <img src='/logo-green-atom.png' alt="studio" width="128">
        </v-row>
        <v-row class="mt-12 mb-6">
            <span class="text-h6">{{ $t('login.welcome-msg') }}</span>
        </v-row>
        <v-row>
            <v-btn @click="doLogin" color="primary">
                {{ loginMsg() }}
            </v-btn>
        </v-row>
    </div>
</template>
<script setup>
import {useAuth0} from "@auth0/auth0-vue";
import Docs from "@/components/common/Docs.vue";
import {onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useI18n} from "vue-i18n";
const SAMPLES_ORG_ID = import.meta.env.VITE_AUTH0_SAMPLES_ORG_ID;

const {loginWithRedirect, isAuthenticated} = useAuth0();
let route = useRoute();
let orga = ref(undefined)
const {t} = useI18n()

onMounted(() => {
    if (route.query.org === 'samples') {
        orga.value = SAMPLES_ORG_ID
    }
    if (!!isAuthenticated.value) {
        let router = useRouter();
        router.push("/")
    }
})

function doLogin() {
    if (orga.value) {
        loginWithRedirect({organization: orga.value})
    } else {
        loginWithRedirect()
    }
}

function loginMsg() {
    if (orga.value) {
        return t('login.login-to', {orga: route.query.org})
    }
    return t('login.login')
}

</script>