<template>
  <Components :components="collections" :enable-tags="true" :component-label="$tc('runner.collection.label', 1)"
              @navigate="navigateToCollection" :allow-creation="false" />
  <Loading :loading="loading" :text="$t('runner.collection.loading-collections')" />
</template>
<script>
import Components from "@/components/common/Components.vue";
import Loading from "@/components/common/Loading.vue";
import CollectionAPI from "@/api/runner/CollectionAPI.js";

export default {
  name: 'Collections',
  components: {Loading, Components},
  data: () => ({
    collections: [],
    filter: "",
    loading: true
  }),
  mounted() {
    CollectionAPI.list(null, true)
        .then(result => {
            this.collections = result
        })
        .finally(() => this.loading = false)
  },
  methods: {
    navigateToCollection(collectionCode) {
      this.$router.push({name: 'runnableCollection', params: {collectionCode: collectionCode}})
    }
  }
}
</script>