<template>
  <v-card width="720px" class="mx-auto">
    <v-toolbar dark color="primary" dense flat>
      <v-toolbar-title class="white--text">
        <span v-if="property.creation" v-html="$t('referential.dataset.properties.new-title', {dataset: datasetCode})" />
        <span v-else v-html="$t('referential.dataset.properties.edit-title', {code: property.code})" />
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <CodeTextField v-model="property.code" :edit="property.creation" class="mb-6" :allow-start-by-number="true"/>
      <v-text-field v-model="property.label" :label="$t('common.label')" variant="outlined" bg-color="white"/>
      <v-select v-model="property.type" :items="types" :label="$t('referential.dataset.properties.type.label')"
                variant="outlined" bg-color="white" item-value="code"/>
      <YesNoField v-model="property.showInFilter" :label="$t('referential.dataset.properties.filterable')" />
      <YesNoField v-model="property.showInTable" :label="$t('referential.dataset.properties.displayable')" />
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn color="error" @click="cancel">{{ $t('common.cancel') }}</v-btn>
      <v-btn color="primary" @click="save">{{ $t('common.save') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import CodeTextField from "@/components/common/CodeTextField.vue";
import YesNoField from "@/components/common/YesNoField.vue";

export default {
  name: 'DatasetProperty',
  components: {YesNoField, CodeTextField},
  props: {
    /** @type {DatasetProperty} */ property: {},
    datasetCode: String
  },
  data: function () {
    return {
      type: null,
      types: [
        {
          title: this.$t('referential.classifier.type.string'),
          code: 'STRING'
        },
        {
          title: this.$t('referential.classifier.type.number'),
          code: 'NUMBER'
        },
        {
          title: this.$t('referential.classifier.type.boolean'),
          code: 'BOOLEAN'
        },
        {
          title: this.$t('referential.classifier.type.date'),
          code: 'DATE'
        }
      ]
    }
  },
  methods: {
    cancel() {
      this.$emit("canceled");
    },
    save() {
      this.$emit("saved", this.property)
    }
  }
}
</script>