<template>
  <v-expansion-panels class="mt-4" v-model="panel">
    <v-expansion-panel value="properties">
      <v-expansion-panel-title>
        <span class="text-h6 font-weight-medium">
          {{ $t('referential.classifier.properties.label') }}
          ({{
            classifier && classifier.properties && classifier.properties.length > 0 ? classifier.properties.length : 0
          }})
        </span>
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <v-card-text>
          <v-card-title>
            <v-row class="my-2">
              <v-spacer/>
              <v-btn color="primary" @click="openProperty=true">{{ $t('referential.classifier.properties.add') }}</v-btn>
            </v-row>
          </v-card-title>
              <v-row class="border my-1 pa-2 bg-primary" dense>
                <v-col cols="2">
                  <span class="text-subtitle-1 font-weight-bold">{{ $t('common.code') }}</span>
                </v-col>
                <v-col cols="4">
                  <span class="text-subtitle-1 font-weight-bold">{{ $t('common.label') }}</span>
                </v-col>
                <v-col>
                  <span class="text-subtitle-1 font-weight-bold">
                    {{ $t('referential.classifier.type.label') }}
                  </span>
                </v-col>
                <v-col>
                </v-col>
              </v-row>
            <div v-for="element in classifier.properties">
              <v-row align="center" class="border pa-2 bg-grey-lighten-5" dense>
                <v-col cols="2">
                  <strong style="font-family: Consolas, Menlo, Courier, monospace; font-size: medium">{{ element.code }}</strong>
                </v-col>
                <v-col cols="4">
                  <span class="text-subtitle-1 font-weight-regular font-italic">{{ element.label }}</span>
                </v-col>
                <v-col>
                  <v-chip color="purple" label>
                    <span style="font-family: monospace, serif">
                      {{ $t('referential.classifier.type.' + element.type.toLowerCase()) }}
                    </span>
                  </v-chip>
                </v-col>
                <v-col class="d-flex flex-row justify-end align-center">
                  <v-icon size="small" color="primary" @click="editProperty(element)">mdi-pencil</v-icon>
                  <v-btn icon="mdi-delete" size="small" color="error" variant="text" class="ml-2"
                         @click="deleteProperty(element)" />
                </v-col>
              </v-row>
            </div>
        </v-card-text>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
  <v-dialog v-model="openProperty" persistent>
    <ClassifierProperty :classifier-code="classifier.code" :property="propertyToEdit" @canceled="clearPropertyDialog"
                        @saved="saveProperty" @deleted="deleteProperty" />
  </v-dialog>
</template>
<script>
import draggable from "vuedraggable";
import ButtonWithTooltip from "@/components/common/ButtonWithTooltip.vue";
import ClassifierAPI from "@/api/referential/ClassifierAPI";
import ClassifierProperty from "@/components/referential/ClassifierProperty.vue";

export default {
  name: "ClassifierProperties",
  components: {ClassifierProperty, ButtonWithTooltip, draggable},
  props: {
    classifier: {}
  },
  data: () => ({
    panel: [],
    properties: [],
    openProperty: false,
    propertyToEdit: {
      creation: true
    }
  }),
  methods: {
    editProperty(property) {
      this.propertyToEdit = property;
      this.openProperty = true;
    },
    saveProperty(property) {
      let promise;
      if (property.creation) {
        promise = ClassifierAPI.addProperty(this.classifier.code, this.propertyToEdit)
      } else {
        promise = ClassifierAPI.updateProperty(this.classifier.code, this.propertyToEdit)
      }
      promise.then(saved => {
        this.classifier.properties = saved.properties
        this.$toast.success(this.$t('referential.classifier.properties.' + (property.creation ? 'created' : 'updated'), {code: property.code}))
      }).finally(() => this.clearPropertyDialog());
    },
    deleteProperty(property) {
      this.$root.$confirm(this.$t('referential.classifier.properties.delete-msg', {code: property.code}), this.$t('referential.classifier.properties.confirm-delete-msg', {code: property.code}))
          .then(confirm => {
            if (confirm) {
              ClassifierAPI.deleteProperty(this.classifier.code, property.code)
                  .then(saved => {
                    this.classifier.properties = saved.properties
                    this.$toast.warning(this.$t('referential.classifier.properties.deleted', {code: property.code}))
                  })
            }
          })
    },
    clearPropertyDialog() {
      this.openProperty = false;
      this.propertyToEdit = {
        creation: true
      };
    }
  }
}
</script>