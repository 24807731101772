<template>
  <nav class="breadcrumbs">
    <a @click="nav('/catalog')" class="breadcrumbs__item"><v-icon icon="mdi-notebook-outline" /></a>
    <a v-for="link in links" @click="nav(link.path)" :class="link.class">{{ link.label }}</a>
  </nav>
</template>
<script>
export default {
  name: 'CatalogBreadcrumb',
  props: {
    reference: String
  },
  data: () => ({
    links: []
  }),
  mounted() {
    let elems = this.reference?.split("/");
    if (elems.length === 1) {
      this.links.push({
        label: elems[0],
        class: "breadcrumbs__item is-active"
      })
    }
    if (elems.length > 1) {
      this.links.push({
        label: elems[0],
        class: "breadcrumbs__item",
        path: "/catalog/collection/" + elems[0]
      })
      if (elems.length > 1) {
        elems.slice(1, elems.length - 1).forEach((elem, index) => this.links.push({
          label: elem,
          class: "breadcrumbs__item",
          path: "/catalog/variable/" + encodeURIComponent(elems.slice(0, index + 2).join("/"))
        }))
        this.links.push({
          label: elems[elems.length - 1],
          class: "breadcrumbs__item is-active"
        })
      }
    }
  },
  methods: {
    nav(route) {
      this.$router.push(route)
    }
  }
}
</script>
<style>
.breadcrumbs {
  border: 1px solid #cbd2d9;
  border-radius: 0.3rem;
  display: inline-flex;
  overflow: hidden;
}

.breadcrumbs__item {
  background: #fff;
  color: #333;
  outline: none;
  padding: 0.75em 0.75em 0.75em 1.25em;
  position: relative;
  text-decoration: none;
  transition: background 0.2s linear;
  cursor: pointer;
}

.breadcrumbs__item:hover:after,
.breadcrumbs__item:hover {
  background: #edf1f5;
}

.breadcrumbs__item:focus:after,
.breadcrumbs__item:focus,
.breadcrumbs__item.is-active:focus {
  background: #323f4a;
  color: #fff;
}

.breadcrumbs__item:after,
.breadcrumbs__item:before {
  background: white;
  bottom: 0;
  clip-path: polygon(50% 50%, -50% -50%, 0 100%);
  content: "";
  left: 100%;
  position: absolute;
  top: 0;
  transition: background 0.2s linear;
  width: 1em;
  z-index: 1;
}

.breadcrumbs__item:before {
  background: #cbd2d9;
  margin-left: 1px;
}

.breadcrumbs__item:last-child {
  border-right: none;
}

.breadcrumbs__item.is-active {
  background: #1B5E20;
  color: #fff;
  pointer-events: none;
}

</style>