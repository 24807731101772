<template>
  <v-expansion-panels class="mt-4" v-model="panel">
    <v-expansion-panel value="properties">
      <v-expansion-panel-title>
        <span class="text-h6 font-weight-medium">
          {{ $tc('catalog.publication.label', 2) }}
          ({{ publications && publications.length > 0 ? publications.length : 0 }})
        </span>
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <v-card-text>
          <div v-if="publications && publications.length > 0">
            <v-row class="border my-1 pa-2 bg-primary" dense>
              <v-col cols="3">
                <span class="text-subtitle-1 font-weight-bold">{{ $t('common.version') }}</span>
              </v-col>
              <v-col cols="3">
                <span class="text-subtitle-1 font-weight-bold">{{ $t('common.start-date') }}</span>
              </v-col>
              <v-col cols="3">
                <span class="text-subtitle-1 font-weight-bold">{{ $t('common.end-date') }}</span>
              </v-col>
              <v-col cols="3">
                <span class="text-subtitle-1 font-weight-bold">{{ $t('common.status') }}</span>
              </v-col>
            </v-row>
            <div v-for="element in publications">
              <v-row align="center" style="cursor: pointer" class="border pa-2 bg-grey-lighten-5" dense>
                <v-col cols="3">
                  <strong class="ml-2" style="font-family: Consolas, Menlo, Courier, monospace; font-size: medium">{{ element.version }}</strong>
                </v-col>
                <v-col cols="3">
                  <span class="text-subtitle-1 font-weight-regular">{{ element.availability.start }}</span>
                </v-col>
                <v-col cols="3">
                  <span class="text-subtitle-1 font-weight-regular">{{ element.availability.end }}</span>
                </v-col>
                <v-col cols="3">
                  <v-chip color="blue" label>
                    <span style="font-family: monospace, serif">
                      {{ $t('catalog.publication.status.' + element.status.toLowerCase()) }}
                    </span>
                  </v-chip>
                </v-col>
              </v-row>
            </div>
          </div>
          <v-alert v-else type="info" prominent variant="outlined">
            <span class="text-subtitle-1 mb-6 mt-3"
                  v-html="$t('catalog.publication.has-no-publications-alert', {type: type.toLowerCase(), code: code})"/>
          </v-alert>
        </v-card-text>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>

import PublicationAPI from "@/api/catalog/PublicationAPI";

export default {
  name: "Publications",
  props: {
    type: String,
    code: String
  },
  data: () => ({
    panel: [],
    publications: []
  }),
  created() {
    if (this.type === 'COLLECTION') {
      PublicationAPI.listCollectionPublications(this.code)
          .then(result => this.publications = result.sort((a, b) => (a.version > b.version) ? -1 : 1))
    }
  },
  methods: {
    syncWithRunner() {

    }
  }
}
</script>