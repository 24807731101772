<template>
    <ModuleHeader code="runner"/>
    <v-tabs v-model="tab" color="primary">
        <v-tab value="collections">
            {{ $tc('runner.collection.label', 2) }}
        </v-tab>
    </v-tabs>
    <v-window v-model="tab">
        <v-window-item value="collections">
            <v-card class="mx-auto">
                <Collections/>
            </v-card>
        </v-window-item>
    </v-window>
</template>
<script>
import ModuleHeader from "@/components/common/ModuleHeader.vue";
import Collections from "@/components/runner/Collections.vue";

export default {
    name: 'Runner',
    components: {Collections, ModuleHeader},
    data: () => ({
        tab: 'collections'
    })
}
</script>