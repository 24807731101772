<template>
  <v-card-actions>
    <CatalogBreadcrumb :reference="$route.params.variableReference"/>
  </v-card-actions>
  <v-card v-if="variable" class="mt-3" density="compact">
      <Loading :loading="loading" :text="$t('catalog.variable.loading-variable', {code: variable.reference.code})" />
      <v-card-actions>
      <v-spacer/>
      <ComponentHistory :component="variable" :edit="edit"/>
      <VariableRules :variable="variable" :edit="edit"/>
      <v-divider vertical class="mx-3"></v-divider>
      <ButtonWithTooltip icon="mdi-pencil" color="primary" :edit="edit" @clicked="edit = !edit"
                         :tooltip-text="$t('catalog.variable.edit')"/>
      <ButtonWithTooltip icon="mdi-content-copy" color="action" :edit="edit" @clicked="duplicate = true"
                         :tooltip-text="$t('catalog.variable.duplicate.label')"/>
      <ButtonWithTooltip icon="mdi-delete" color="error" :edit="edit" @clicked="deleteVariable"
                         :tooltip-text="$t('catalog.variable.delete')"/>
    </v-card-actions>
    <v-card-text>
      <v-row align="center" justify="center">
        <v-col cols="4">
          <CodeTextField v-model="variable.reference.code" :edit="false"/>
        </v-col>
        <v-col cols="5">
          <v-text-field v-model="variable.label" label="Label" :readonly="!edit" :variant="edit ? 'outlined' : 'plain'"
                        hide-details :bg-color="edit ? 'white' : ''" />
        </v-col>
        <v-col cols="2">
          <v-chip color="purple" class="mr-2" label>
            <span style="font-family: monospace, serif">{{ $t('catalog.variable.nature.' + variable.nature.toLowerCase()) }}</span>
          </v-chip>
          <v-chip color="blue" label>
            <span style="font-family: monospace, serif">{{ $t('catalog.variable.type.' + variable.type.toLowerCase()) }}</span>
          </v-chip>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="11">
          <v-card v-if="variable.type !== 'COMPOSITE'">
            <v-toolbar color="primary">
              <v-toolbar-title>{{ $t('common.definition') }}</v-toolbar-title>
            </v-toolbar>
            <div v-if="variable.type === 'RECORD'">
              <v-row align="center" class="pa-3">
                <v-col cols="3">
                  <span class="text-subtitle-1 font-weight-medium d-flex align-center ml-2">
                    {{ $t('catalog.variable.related-dataset') }}
                  </span>
                </v-col>
                <v-col cols="9">
                  <v-chip v-if="!edit">
                    <span>{{ referentialItemTitle(selectedDataset) }}</span>
                    <v-icon size="small" class="ml-2" @click="openReferentialItem(selectedDataset, 'DATASET')">
                      mdi-open-in-new
                    </v-icon>
                  </v-chip>
                  <v-select v-else v-model="selectedDataset" :items="datasets" item-value="code" return-object
                            :item-title="referentialItemTitle" variant="outlined" hide-details bg-color="white" />
                </v-col>
              </v-row>
              <v-divider class="mx-2"></v-divider>
              <v-row align="center" class="pa-3">
                <v-col cols="3">
                  <span class="text-subtitle-1 font-weight-medium d-flex align-center ml-2">
                    {{ $t('catalog.variable.include-classifiers') }}
                  </span>
                </v-col>
                <v-col cols="9">
                  <div v-if="!edit">
                    <v-chip v-for="classifier in selectedClassifiers" :key="classifier.code" class="mr-2">
                      <span>{{ referentialItemTitle(classifier) }}</span>
                      <v-icon size="small" class="ml-2" @click="openReferentialItem(classifier, 'CLASSIFIER')">
                        mdi-open-in-new
                      </v-icon>
                    </v-chip>
                  </div>
                  <v-autocomplete v-else v-model="selectedClassifiers" :items="classifiers" :readonly="!edit" multiple
                                  chips :closable-chips="edit" :variant="edit ? 'outlined' : 'plain'" hide-details
                                  :style="edit ? 'background-color: white' : ''" :item-title="referentialItemTitle"
                                  return-object />
                 </v-col>
              </v-row>
              <v-divider class="mb-4 mx-2"></v-divider>
            </div>
            <ValueOrFormula class="px-3" :label="$t('common.value')" :edit="edit" :type="variable.type"
                            v-model="variable.value" />
          </v-card>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="11">
          <v-card>
            <v-toolbar color="primary">
              <v-toolbar-title>{{ $tc('common.parameter', 2) }}</v-toolbar-title>
            </v-toolbar>
            <v-row>
              <v-col cols="2">
                <v-tabs v-model="tab" direction="vertical" color="primary">
                  <v-tab value="mandatory">
                    <v-icon left class="mr-2">
                      mdi-flare
                    </v-icon>
                    {{ $t('catalog.variable.requirement') }}
                  </v-tab>
                  <v-tab value="multiple">
                    <v-icon left class="mr-2">
                      mdi-numeric-1-box-multiple-outline
                    </v-icon>
                    {{ $t('catalog.variable.multiplicity') }}
                  </v-tab>
                  <v-tab v-if="variable.type !== 'COMPOSITE' && variable.type !== 'BOOLEAN' && variable.type !== 'RECORD'" value="controls">
                    <v-icon left class="mr-2">
                      mdi-lock-check-outline
                    </v-icon>
                    {{ $t('catalog.variable.value-controls') }}
                  </v-tab>
                </v-tabs>
              </v-col>
              <v-col>
                <v-window v-model="tab" style="width: 100%">
                  <v-window-item value="mandatory">
                    <v-row align="center" justify="center">
                      <v-col cols="11">
                        <ValueOrFormula :label="$t('catalog.variable.mandatory')" :edit="edit"
                                        v-model="variable.mandatory" type="boolean" />
                      </v-col>
                    </v-row>
                  </v-window-item>
                  <v-window-item value="multiple">
                    <v-row align="center" justify="center">
                      <v-col cols="11">
                        <v-row align="center" class="mt-2">
                          <v-col cols="3">
                            <span class="text-subtitle-1 font-weight-medium mb-2 ml-2">
                              {{ $t('catalog.variable.multiple') }}
                            </span>
                          </v-col>
                          <v-col cols="9">
                            <v-switch v-model="variable.multiple" :disabled="!edit" color="primary" inset hide-details
                                      :label="`${variable.multiple? $t('common.yes') : $t('common.no')}`" />
                          </v-col>
                        </v-row>
                        <div v-if="variable.multiple">
                          <div v-if="variable.type === 'COMPOSITE'">
                            <v-divider class="mb-2"></v-divider>
                            <v-row align="center">
                              <v-col cols="3">
                                <span class="text-subtitle-1 font-weight-medium mb-2 ml-2">
                                  {{ $t('catalog.variable.loop-over-other-variables') }}
                                </span>
                              </v-col>
                              <v-col cols="9">
                                <v-switch v-model="looping" hide-details :disabled="!edit" color="primary" inset
                                          :label="`${looping? $t('common.yes') : $t('common.no')}`" />
                              </v-col>
                            </v-row>
                            <div v-if="looping">
                              <v-row align="center">
                                <v-col cols="3">
                                  <span class="text-subtitle-1 font-weight-medium mb-2 ml-2">
                                    {{ $t('catalog.variable.over') }}
                                  </span>
                                </v-col>
                                <v-col cols="9">
                                  <v-row v-for="(loop, i) in loopOver" class="ma-0">
                                    <v-col>
                                      <v-text-field v-if="!edit" v-model="loop.var" variant="plain"  density="compact"
                                                    :label="$t('catalog.variable.label')" readonly hide-details/>
                                      <v-select v-else v-model="loop.var" :items="availableLoopingVars" hide-details
                                                variant="outlined" bg-color="white"
                                                :label="$t('catalog.variable.label')" />
                                    </v-col>
                                    <v-col>
                                      <CodeTextField v-model="loop.code" :edit="edit"/>
                                    </v-col>
                                    <v-col cols="1">
                                      <v-btn v-if="edit" icon="mdi-close" size="small" color="error" variant="text"
                                             @click="loopOver.splice(i, 1)"></v-btn>
                                    </v-col>
                                  </v-row>
                                  <div class="d-flex justify-center">
                                    <v-btn v-if="edit" variant="text" color="primary" @click="addLoopingVariable">
                                      {{ $t('catalog.variable.add-looping-variable') }}
                                    </v-btn>
                                  </div>
                                </v-col>
                              </v-row>
                            </div>
                          </div>
                          <div v-if="!looping">
                            <v-divider class="mb-4"></v-divider>
                            <ValueOrFormula :label="$t('catalog.variable.min-occurrence')" :edit="edit"
                                            v-model="variable.minOccurrence" type="number" />
                            <v-divider class="mb-4"></v-divider>
                            <ValueOrFormula :label="$t('catalog.variable.max-occurrence')" :edit="edit"
                                            v-model="variable.maxOccurrence" type="number" />
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-window-item>
                  <v-window-item value="controls">
                    <v-row align="center" justify="center" class="my-2">
                      <v-col cols="11">
                        <div v-if="variable.type === 'NUMBER'">
                          <ValueOrFormula :label="$t('catalog.variable.min-value')" v-model="variable.minValue"
                                          :edit="edit" :type="variable.type" />
                          <v-divider class="mb-4"></v-divider>
                          <ValueOrFormula :label="$t('catalog.variable.max-value')" v-model="variable.maxValue"
                                          :edit="edit" :type="variable.type" />
                        </div>
                        <div v-if="variable.type === 'DATE'">
                          <ValueOrFormula :label="$t('catalog.variable.min-date')" v-model="variable.minDate"
                                          :edit="edit" :type="variable.type" />
                          <v-divider class="mb-4"></v-divider>
                          <ValueOrFormula :label="$t('catalog.variable.max-date')" v-model="variable.maxDate"
                                          :edit="edit" :type="variable.type" />
                        </div>
                        <div v-if="variable.type === 'NUMBER'">
                          <v-divider class="mb-4"></v-divider>
                          <ValueOrFormula :label="$t('catalog.variable.pace')" v-model="variable.pace"
                                          :edit="edit" :type="variable.type" />
                          <v-divider class="mb-4"></v-divider>
                          <Value :label="$t('catalog.variable.scale')" v-model="variable.scale"
                                 :edit="edit" type="number" class="mb-2" />
                          <div v-if="variable.scale > 0">
                            <v-divider class="mb-4"></v-divider>
                            <Value :label="$t('catalog.variable.rounding-mode')" v-model="variable.roundingMode"
                                   :edit="edit" type="string" :items="roundingModes" />
                          </div>
                        </div>
                        <div v-if="variable.type === 'STRING'">
                          <ValueOrFormula :label="$t('catalog.variable.min-length')" v-model="variable.minLength"
                                          :edit="edit" type="number" />
                          <v-divider class="mb-4"></v-divider>
                          <ValueOrFormula :label="$t('catalog.variable.max-length')" v-model="variable.maxLength"
                                          :edit="edit" type="number" />
                          <v-divider class="mb-4"></v-divider>
                          <Value :label="$t('catalog.variable.pattern')" v-model="variable.valuePattern"
                                 :edit="edit" :type="variable.type" class="mb-2" />
                          <v-divider class="mb-4"></v-divider>
                          <v-row align="center">
                            <v-col cols="3">
                              <span class="text-subtitle-1 font-weight-medium d-flex align-center mb-2 ml-2">
                                {{ $t('catalog.variable.valid-values') }}
                              </span>
                            </v-col>
                            <v-col cols="9">
                              <div v-if="!edit">
                                <v-chip v-for="validValue in variable.validValues" class="mr-1 mb-1">
                                  {{ validValue }}
                                </v-chip>
                              </div>
                              <v-combobox v-else v-model="variable.validValues" :items="[]" multiple chips hide-details
                                          closable-chips variant="outlined" style="background-color: white" />
                            </v-col>
                          </v-row>
                        </div>
                      </v-col>
                    </v-row>
                  </v-window-item>
                </v-window>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn v-if="edit" color="error" @click="cancelEditing">{{ $t('common.cancel') }}</v-btn>
      <v-btn v-if="edit" color="primary" @click="updateVariable">{{ $t('common.save') }}</v-btn>
    </v-card-actions>
  </v-card>
  <Properties v-if="variable" :variable="variable"></Properties>
  <SubVariables v-if="variable && variable.type === 'COMPOSITE'" :reference="reference" :label="variable.label" v-model="variables"/>
  <RecordSubVariables v-if="variable && variable.type === 'RECORD'" :key="recordSubVariablesKey" :variable="variable" />
  <v-dialog v-model="duplicate" persistent>
    <DuplicateVariable :to-duplicate="variable" @canceled="duplicate = false" @duplicated="duplicateVariable"></DuplicateVariable>
  </v-dialog>
</template>
<script>
import VariableAPI from "@/api/catalog/VariableAPI";
import CatalogBreadcrumb from "@/components/catalog/CatalogBreadcrumb.vue";
import SubVariables from "@/components/catalog/SubVariables.vue";
import ValueOrFormula from "@/components/catalog/ValueOrFormula.vue";
import Value from "@/components/catalog/Value.vue";
import Properties from "@/components/catalog/Properties.vue";
import ButtonWithTooltip from "@/components/common/ButtonWithTooltip.vue";
import ComponentHistory from "@/components/catalog/ComponentHistory.vue";
import VariableRules from "@/components/catalog/VariableRules.vue";
import CodeTextField from "@/components/common/CodeTextField.vue";
import DuplicateVariable from "@/components/catalog/DuplicateVariable.vue";
import {computeVariableReference} from "@/util"
import DatasetAPI from "@/api/referential/DatasetAPI";
import RecordSubVariables from "@/components/catalog/RecordSubVariables.vue";
import ClassifierAPI from "@/api/referential/ClassifierAPI";
import Loading from "@/components/common/Loading.vue";

export default {
  name: "Variable",
  components: {
      Loading,
    RecordSubVariables,
    DuplicateVariable,
    CodeTextField, VariableRules, ComponentHistory, ButtonWithTooltip, Properties, Value, ValueOrFormula, CatalogBreadcrumb, SubVariables},
  data: () => ({
    /** @type {Variable} */ variable: null,
    /** @type {Array<Variable>} */ variables: [],
    edit: false,
    roundingModes: ['UP', 'DOWN', 'CEILING', 'FLOOR', 'HALF_UP', 'HALF_DOWN', 'HALF_EVEN', 'UNNECESSARY'],
    tab: 'mandatory',
    looping: false,
    loopOver: [{var: undefined, code: undefined}],
    availableLoopingVars: [],
    duplicate: false,
    datasets: [],
    classifiers: [],
    selectedDataset: null,
    selectedClassifiers: [],
    recordSubVariablesKey: 0,
    loading: true
  }),
  computed: {
    reference() {
      return computeVariableReference(this.variable);
    }
  },
  created() {
    VariableAPI.find(this.$route.params.variableReference)
        .then(result => {
          this.variable = result;
          this.$store.dispatch('setCurrentVariable', this.reference)
          if (this.variable.loopOver) {
            this.looping = true;
            this.loopOver = [];
            new Map(Object.entries(this.variable.loopOver)).forEach((k, v) => this.loopOver.push({var: k, code: v}));
          }
          if (this.variable.type === "COMPOSITE") {
            VariableAPI.listCompositeVariables(this.$route.params.variableReference)
                .then(result2 => this.variables = result2)
            VariableAPI.getAvailableLoopingVariablesReferences(this.$route.params.variableReference)
                .then(result3 => this.availableLoopingVars = result3)
          }
          if (this.variable.type === 'RECORD') {
            DatasetAPI.list()
                .then(result => {
                  this.datasets = result
                  this.selectedDataset = this.datasets.find(dataset => dataset.code === this.variable.datasetCode)
                })
          }
        })
        .finally(() => this.loading = false)
  },
  watch: {
    selectedDataset: function (value) {
      if (value) {
        ClassifierAPI.listByDataset(value.code)
            .then(result => {
              this.selectedClassifiers = result.filter(classifier => this.variable.classifiers?.includes(classifier.code))
              this.classifiers = result
            })
      }
    }
  },
  methods: {
    deleteVariable() {
      let confirmation = this.variable.type === 'COMPOSITE' ? this.variable.reference.code : undefined
      this.$root.$confirm(this.$t('catalog.variable.delete-msg', {label: this.variable.label}), this.$t('catalog.variable.confirm-delete-msg', {label: this.variable.label}), confirmation)
          .then(confirm => {
            if (confirm) {
              VariableAPI.deleteVariable(this.reference)
                  .then(deleteOK => {
                    if (deleteOK) {
                      this.$toast.warning(this.$t('catalog.variable.deleted', {label: this.variable.label}))
                      if (this.variable.reference.parent && this.variable.reference.parent.includes('/')) {
                        this.$router.push({name: 'variable', params: {variableReference: this.variable.reference.parent}})
                      } else {
                        this.$router.push({name: 'collection', params: {collectionCode: this.variable.reference.parent}})
                      }
                    } else {
                      this.$toast.error(this.$t('catalog.variable.error-on-delete', {label: this.variable.label}))
                    }
                  })
            }
          })
    },
    cancelEditing() {
      this.edit = !this.edit;
    },
    updateVariable() {
      if (this.looping && this.loopOver) {
        const loopOver = new Map();
        this.loopOver.filter(loop => loop.var).forEach(loop => loopOver.set(loop.code, loop.var));
        this.variable.loopOver = Object.fromEntries(loopOver)
      } else {
        this.loopOver = [{var: undefined, code: undefined}];
        this.variable.loopOver = undefined;
      }
      if (this.variable.type === 'RECORD') {
        this.variable.datasetCode = this.selectedDataset.code
        this.variable.classifiers = this.selectedClassifiers.map(classifier => classifier.code);
      }
      VariableAPI.updateVariable(this.variable)
          .then(() => {
            this.$toast.success(this.$t('catalog.variable.saved', {label: this.variable.label}))
            this.recordSubVariablesKey += 1
          })
          .finally(() => this.edit = false)
    },
    addLoopingVariable() {
      this.loopOver.push({var: undefined, code: undefined})
    },
    duplicateVariable(duplicateInfos) {
      VariableAPI.duplicateVariable(this.reference, duplicateInfos)
          .then(created => {
            const createdReference = computeVariableReference(created)
            this.$toast.success(this.$t('catalog.variable.duplicated', {origin: this.variable.label, label: created.label}))
            this.$router.push({name: 'variable', params: {variableReference: createdReference}})
          })
    },
    referentialItemTitle(item) {
      if (item) {
        return `${item.code} - ${item.label}`
      }
    },
    openReferentialItem(item, nature) {
      let route = undefined
      if (nature === 'CLASSIFIER') {
        route = this.$router.resolve({name: 'classifier', params: {classifierCode: item.code}});
      } else if (nature === 'DATASET') {
        route = this.$router.resolve({name: 'dataset', params: {datasetCode: item.code}});
      }
      if (route) {
        window.open(route.href, '_blank');
      }
    }
  }
}
</script>