<template>
  <v-card width="720px" class="mx-auto">
    <v-toolbar dark color="primary" dense flat>
      <v-toolbar-title class="white--text">
        <span v-html="$t('catalog.variable.create-under', {parent: parent})"/>
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <CodeTextField v-model="code" :edit="true" class="mb-6"/>
      <v-text-field v-model="label" :label="$t('common.label')" variant="outlined" bg-color="white"/>
      <v-select v-model="nature" :items="natures" :label="$t('catalog.variable.nature.label')" variant="outlined"
                bg-color="white" return-object></v-select>
      <v-select v-model="type" :items="types" :label="$t('catalog.variable.type.label')" variant="outlined"
                bg-color="white" return-object></v-select>
      <v-select v-if="type && type.code === 'RECORD'" v-model="dataset" :items="datasets" item-value="code"
                :item-title="datasetItemTitle" :label="$t('catalog.variable.related-dataset')" variant="outlined"
                bg-color="white" return-object></v-select>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn color="error" @click="cancel">{{ $t('common.cancel') }}</v-btn>
      <v-btn color="primary" @click="save">{{ $t('common.save') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import CodeTextField from "@/components/common/CodeTextField.vue";
import DatasetAPI from "@/api/referential/DatasetAPI";

/**
 * @typedef {Object} VariableCreation
 * @property {string} parent
 * @property {string} code
 * @property {string} label
 * @property {'string'|'composite'|'boolean'|'number'|'date'} type
 */

export default {
  name: "CreateVariable",
  components: {CodeTextField},
  props: {
    parent: String
  },
  data: function () {
    return {
      code: null,
      type: null,
      label: null,
      nature: null,
      dataset: null,
      natures: [
        {
          title: this.$t('catalog.variable.nature.ingress'),
          code: "INGRESS"
        },
        {
          title: this.$t('catalog.variable.nature.computed'),
          code: "COMPUTED"
        }
      ],
      types: [{
        title: this.$t('catalog.variable.type.composite'),
        code: 'COMPOSITE'
      }, {
        title: this.$t('catalog.variable.type.string'),
        code: 'STRING'
      }, {
        title: this.$t('catalog.variable.type.number'),
        code: 'NUMBER'
      }, {
        title: this.$t('catalog.variable.type.boolean'),
        code: 'BOOLEAN'
      }, {
        title: this.$t('catalog.variable.type.date'),
        code: 'DATE'
      }, {
        title: this.$t('catalog.variable.type.record'),
        code: 'RECORD'
      }],
      datasets: []
    }
  },
  watch: {
    type: function (selectedType) {
      if (selectedType.code === 'RECORD') {
        DatasetAPI.list()
            .then(result => this.datasets = result)
      }
    }
  },
  methods: {
    cancel() {
      this.$emit("canceled");
    },
    save() {
      /** @this {Variable} */
      let variableToCreate = {
        reference: {
          parent: this.parent,
          code: this.code
        },
        label: this.label,
        type: this.type.code,
        nature: this.nature.code,
        datasetCode: this.dataset ? this.dataset.code : null
      }
      this.$emit("saved", variableToCreate)
    },
    datasetItemTitle(dataset) {
      return `${dataset.code} - ${dataset.label}`
    }
  }
}
</script>