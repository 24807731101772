<template>
  <v-tooltip v-model="tooltip" transition="scroll-y-transition" :location="orientation">
    <template v-slot:activator="{ props }">
      <v-btn v-if="label" v-bind="props" :prepend-icon="icon" :color="edit ? '' : color" :disabled="edit"
             @click="onClick" :size="size" :density="density" :variant="variant">{{ label }}
      </v-btn>
      <v-btn v-else v-bind="props" :icon="icon !== undefined" :color="edit ? '' : color" :disabled="edit"
             @click="onClick" :size="size" :density="density" :variant="variant">
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
    </template>
    <span v-html="tooltipText"></span>
  </v-tooltip>
</template>
<script>
export default {
  name: "ButtonWithTooltip",
  props: {
    icon: String,
    color: String,
    tooltipText: String,
    edit: Boolean,
    dot: Boolean,
    label: String,
    variant: {
      type: String,
      default: 'text'
    },
    density: {
      type: String,
      default: 'default'
    },
    size: {
      type: String,
      default: 'default'
    },
    orientation: {
      type: String,
      default: 'bottom'
    }
  },
  data: () => ({
    tooltip: false
  }),
  methods: {
    onClick() {
      this.tooltip = false;
      this.$emit('clicked');
    }
  }
}
</script>
