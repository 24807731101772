<template>
    <v-card width="720px" class="mx-auto">
        <v-toolbar dark color="primary" dense flat>
            <v-toolbar-title class="white--text">{{ $t('common.create', {type: type}) }}</v-toolbar-title>
        </v-toolbar>
        <v-tabs v-model="tab" fixed-tabs color="primary" theme="dark">
            <v-tab value="form">
                <v-icon class="mr-1">mdi-format-columns</v-icon>
                {{ $t('common.form') }}
            </v-tab>
            <v-tab value="json">
                <v-icon class="mr-1">mdi-code-json</v-icon>
                {{ $t('common.json') }}
            </v-tab>
            <v-tab value="file">
                <v-icon class="mr-1">mdi-paperclip</v-icon>
                {{ $t('common.file') }}
            </v-tab>
        </v-tabs>
        <v-window v-model="tab">
            <v-window-item value='form'>
                <slot name="form"></slot>
            </v-window-item>
            <v-window-item value='json'>
                <v-card>
                    <v-ace-editor v-model:value="jsonToImport" lang="json" style="height: 180px" :print-margin="false"/>
                    <v-select v-if="tab === 'json' && showImportMode" v-model="importMode" :items="importModes"
                              variant="outlined" return-object
                              hide-details :item-title="i => $t('common.import.modes.' + i.toLowerCase())"
                              :label="$t('common.import.modes.title')" class="mt-4 mx-2" bg-color="white"/>
                </v-card>
            </v-window-item>
            <v-window-item value='file'>
                <v-card>
                    <div style="height: 180px">
                        <v-file-input v-model="selectedFiles"
                                      :label="$t('common.import.file-mode-field-label')"
                                      variant="outlined" hide-details class="ml-4 mr-2 pt-6" bg-color="white"
                                      @update:modelValue="importFromFile"/>
                        <v-alert type="info" prominent variant="outlined" class="mt-4 mx-2">
                            {{ $t('common.import.file-mode-caution') }}
                        </v-alert>
                    </div>
                    <v-select v-if="tab === 'file' && showImportMode" v-model="importMode" :items="importModes"
                              :label="$t('common.import.modes.title')"
                              variant="outlined" return-object :item-title="i => $t('common.import.modes.' + i.toLowerCase())"
                              hide-details class="mt-4 mx-2" bg-color="white"/>
                </v-card>
            </v-window-item>
        </v-window>
        <v-card-actions class="d-flex align-center">
            <v-spacer/>
            <v-btn color="error" @click="$emit('canceled')">{{ $t('common.cancel') }}</v-btn>
            <v-btn v-if="tab === 'form'" color="primary" @click="$emit('saved')">{{ $t('common.save') }}</v-btn>
            <v-btn v-if="tab === 'json' || tab === 'file'" color="primary" :disabled="!jsonToImport" @click="importJson">
                {{ $t('common.import.label') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script setup>
import {ref} from "vue";
import {VAceEditor} from "vue3-ace-editor";
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-chrome';
defineProps(['type', 'showImportMode'])
const emit = defineEmits(['canceled', 'saved', 'imported'])

const tab = ref('form')
const jsonToImport = ref('')
const selectedFiles = ref(null)
const importMode = ref('CREATE')
const importModes = ['CREATE', 'OVERRIDE', 'MERGE']

const importFromFile = () => {
    if (!selectedFiles.value) return;

    const reader = new FileReader();

    reader.onload = (event) => {
        jsonToImport.value = event.target.result
    };
    const file = selectedFiles.value[0];
    reader.readAsText(file);
}

const importJson = () => {
    let importedJson = Object.assign(JSON.parse(jsonToImport.value), {mode: importMode.value});
    emit("imported", importedJson)
}
</script>