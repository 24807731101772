<template>
  <v-menu rounded>
    <template v-slot:activator="{ props }">
      <v-btn icon v-bind="props">
        <v-avatar size="large">
          <v-img :src="user.picture"/>
        </v-avatar>
      </v-btn>
    </template>
    <v-card min-width="200px">
      <v-card-text>
        <div class="mx-auto text-center">
          <v-avatar size="x-large">
            <v-img :src="user.picture"/>
          </v-avatar>
          <p class="text-h5 my-1">{{ user.nickname }}</p>
          <p class="text-body-1 mt-1">
            {{ user.email }}
          </p>
          <v-divider class="my-3"></v-divider>
          <v-row class="pa-0">
            <v-col class="pa-1">
              <ButtonWithTooltip icon="mdi-cog" :tooltip-text="t('common.settings')" @clicked="router.push('settings')"/>
            </v-col>
            <v-divider vertical/>
            <v-col class="pa-1">
              <ButtonWithTooltip icon="mdi-logout" :tooltip-text="t('common.logout')" @clicked="doLogout"/>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>
<script setup>
import {useAuth0} from "@auth0/auth0-vue";
import {useI18n} from 'vue-i18n'
import {useRouter} from "vue-router";
import ButtonWithTooltip from "@/components/common/ButtonWithTooltip.vue"

const {user, logout} = useAuth0();
const {t} = useI18n()
const router = useRouter();

function doLogout() {
    logout({
        returnTo: import.meta.env.VITE_AUTH0_LOGOUT_RETURN_TO
    })
}
</script>