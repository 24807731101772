<template>
  <v-row align="center">
    <v-col cols="3">
      <span class="text-subtitle-1 font-weight-medium d-flex align-center ml-2">{{ label }}</span>
    </v-col>
    <v-col cols="9">
      <v-switch v-if="type === 'boolean'" v-model="model" hide-details color="primary" inset :disabled="!edit"
                :label="`${model? model.toString() : 'false'}`" />
      <div v-else>
        <v-text-field v-if="!items || items.length === 0" v-model="model" :type="type" hide-details
                      :variant="edit ? 'outlined' : 'plain'" :readonly="!edit" density="compact"
                      :bg-color="edit ? 'white' : ''" />
        <div v-else>
          <v-text-field v-if="!edit" v-model="model" hide-details variant="plain" readonly density="compact"/>
          <v-select v-else v-model="model" :items="items" variant="outlined" dense hide-details bg-color="white" />
        </div>
      </div>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "Value",
  props: {
    modelValue: null,
    label: String,
    type: String,
    items: {
      type: Array,
      defaultValue: []
    },
    edit: Boolean
  },
  computed:{
    model: {
      get() { return this.modelValue },
      set(newValue) { this.$emit('update:modelValue', newValue) }
    }
  }
}
</script>