<template>
    <div v-if="dataset" class="align-center">
        <Loading :loading="loading" :text="$t('referential.dataset.loading-dataset', {code: dataset.code})"/>
        <v-card-actions>
            <ReferentialBreadcrumb :dataset="dataset.code"/>
            <v-spacer/>
            <ButtonWithTooltip icon="mdi-download" color="action" :edit="edit" @clicked="openJson = true"
                               variant="contained-text" size="large" density="comfortable"
                               :tooltip-text="$t('referential.dataset.export.label')"
                               :label="$t('common.export.label')"/>
        </v-card-actions>
        <v-card class="mt-3" density="compact">
            <v-card-actions>
                <v-spacer/>
                <ButtonWithTooltip icon="mdi-pencil" color="primary" :edit="edit" @clicked="edit = !edit"
                                   :tooltip-text="$t('referential.dataset.edit')"/>
                <ButtonWithTooltip icon="mdi-delete" color="error" :edit="edit" @clicked="deleteDataset"
                                   :tooltip-text="$t('referential.dataset.delete')"/>
            </v-card-actions>

            <v-card-text>
                <v-row align="center" justify="center">
                    <v-col cols="4">
                        <CodeTextField v-model="dataset.code" :edit="false"/>
                    </v-col>
                    <v-col cols="7">
                        <v-text-field v-model="dataset.label" :label="$t('common.label')" :readonly="!edit"
                                      :variant="edit ? 'outlined' : 'plain'" hide-details
                                      :bg-color="edit ? 'white' : ''"/>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions>
                <v-spacer/>
                <v-btn v-if="edit" color="error" @click="cancelEditing">{{ $t('common.cancel') }}</v-btn>
                <v-btn v-if="edit" color="primary" @click="updateDataset">{{ $t('common.save') }}</v-btn>
            </v-card-actions>
        </v-card>
        <DatasetProperties :dataset="dataset"/>
        <DatasetRecords :dataset="dataset"/>
        <DatasetClassifiers :dataset="dataset"/>
        <v-dialog v-model="openJson" persistent>
            <DisplayJson :code="dataset.code" type="dataset" :data="dataset" @closed="openJson = false"/>
        </v-dialog>
    </div>
</template>
<script>
import DatasetAPI from "@/api/referential/DatasetAPI";
import ButtonWithTooltip from "@/components/common/ButtonWithTooltip.vue";
import CodeTextField from "@/components/common/CodeTextField.vue";
import DatasetProperties from "@/components/referential/DatasetProperties.vue";
import DatasetRecords from "@/components/referential/DatasetRecords.vue";
import ReferentialBreadcrumb from "@/components/referential/ReferentialBreadcrumb.vue";
import DatasetClassifiers from "@/components/referential/DatasetClassifiers.vue";
import Loading from "@/components/common/Loading.vue";
import DisplayJson from "@/components/common/DisplayJson.vue";

export default {
    name: "Dataset",
    components: {
        DisplayJson,
        Loading,
        DatasetClassifiers, ReferentialBreadcrumb, DatasetRecords, DatasetProperties, CodeTextField, ButtonWithTooltip
    },
    data: () => ({
        /** @type {Dataset} */ dataset: null,
        edit: false,
        loading: true,
        openJson: false
    }),
    mounted() {
        DatasetAPI.find(this.$route.params.datasetCode)
            .then(result => this.dataset = result)
            .finally(() => this.loading = false)
    },
    methods: {
        deleteDataset() {
            this.$root.$confirm(this.$t('referential.dataset.delete-msg', {code: this.dataset.code}), this.$t('referential.dataset.confirm-delete-msg', {code: this.dataset.code}), this.dataset.code)
                .then(confirm => {
                    if (confirm) {
                        DatasetAPI.delete(this.dataset.code)
                            .then(deleteOK => {
                                if (deleteOK) {
                                    this.$toast.warning(this.$t('referential.dataset.deleted', {code: this.dataset.code}))
                                    this.$router.push({name: 'referential'});
                                } else {
                                    this.$toast.error(this.$t('referential.dataset.error-on-delete', {code: this.dataset.code}))
                                }
                            })
                    }
                })
        },
        cancelEditing() {
            this.edit = !this.edit;
        },
        updateDataset() {
            DatasetAPI.update(this.dataset)
                .then(() => this.$toast.success(this.$t('referential.dataset.updated', {code: this.dataset.code})))
                .finally(() => this.edit = false)
        }
    }
}
</script>