<template>
    <v-expansion-panels class="mt-4" v-model="panel">
        <v-expansion-panel value="records" eager>
            <v-expansion-panel-title>
        <span class="text-h6 font-weight-medium">
          {{ $t('referential.records.label') }} ({{ totalElements }})
        </span>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
                <v-row class="mt-4 mr-4" align="center">
                    <v-spacer/>
                    <v-btn v-if="deleteFilteredRecordsEnabled" color="warning" @click="deleteFilteredRecords"
                           class="mr-2">
                        {{ $t('referential.dataset.records.delete-filtered-records') }}
                    </v-btn>
                    <v-menu anchor="bottom end" rounded>
                        <template v-slot:activator="{ props }">
                            <v-btn v-bind="props">
                                {{ $t('common.actions') }}
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                        </template>
                        <v-list density="compact" class="mt-2">
                            <v-list-item prepend-icon="mdi-plus-thick" @click="openRecord = true"
                                         :title="$t('referential.records.add')"/>
                            <v-list-item prepend-icon="mdi-publish" @click="openImportRecords = true"
                                         :title="$t('referential.records.import-from-csv')"/>
                            <v-list-item prepend-icon="mdi-download" @click="exportDatasetRecords"
                                         :title="$t('referential.records.download-as-csv')"/>
                            <v-list-item v-if="!deleteFilteredRecordsEnabled" prepend-icon="mdi-delete"
                                         :title="$t('referential.dataset.records.enable-delete-filter')"
                                         @click="deleteFilteredRecordsEnabled = true"/>
                            <v-list-item v-if="deleteFilteredRecordsEnabled" prepend-icon="mdi-delete-off"
                                         :title="$t('referential.dataset.records.disable-delete-filter')"
                                         @click="deleteFilteredRecordsEnabled = false"/>
                        </v-list>
                    </v-menu>
                </v-row>
                <DatasetRecordSearch :key="datasetRecordSearchKey" :dataset-code="dataset.code" @selected="selectRecord"
                                     @totalElements="setTotalElements" @filter="setFilter"/>
            </v-expansion-panel-text>
        </v-expansion-panel>
    </v-expansion-panels>
    <v-dialog v-model="openRecord" persistent>
        <DatasetRecord :dataset="dataset" :record="selectedRecord" @canceled="clearRecordDialog" @saved="saveRecord"
                       @deleted="deleteRecord"/>
    </v-dialog>
    <v-dialog v-model="openImportRecords" persistent>
        <ImportRecords @canceled="openImportRecords = false" @imported="importDatasetRecords" />
    </v-dialog>
    <Loading :loading="loading" :text="loadingText"/>
</template>
<script>
import ButtonWithTooltip from "@/components/common/ButtonWithTooltip.vue";
import draggable from "vuedraggable";
import DatasetAPI from "@/api/referential/DatasetAPI";
import DatasetRecord from "@/components/referential/DatasetRecord.vue";
import DatasetRecordSearch from "@/components/referential/DatasetRecordSearch.vue";
import Loading from "@/components/common/Loading.vue";
import ImportRecords from "@/components/referential/ImportRecords.vue";

export default {
    name: "DatasetRecords",
    components: {
        ImportRecords,
        Loading, DatasetRecordSearch, DatasetRecord, ButtonWithTooltip, draggable},
    props: {
        dataset: {}
    },
    data: () => ({
        openRecord: false,
        openImportRecords: false,
        drag: false,
        panel: [],
        totalElements: '?',
        selectedRecord: {
            creation: true,
            properties: {}
        },
        datasetRecordSearchKey: 0,
        deleteFilteredRecordsEnabled: false,
        filter: {},
        loading: false,
        loadingText: null
    }),
    created() {
        this.clearRecordDialog()
    },
    methods: {
        clearRecordDialog() {
            this.openRecord = false;
            this.selectedRecord = {
                creation: true,
                properties: {}
            };
            if (this.dataset.properties) {
                this.dataset.properties.forEach(property => this.selectedRecord.properties[property.code] = {value: null})
            }
        },
        selectRecord(record) {
            this.selectedRecord = record;
            this.openRecord = true;
        },
        saveRecord(record) {
            let promise;
            record.datasetCode = this.dataset.code
            if (record.creation) {
                promise = DatasetAPI.addRecord(this.dataset.code, record)
            } else {
                promise = DatasetAPI.updateRecord(this.dataset.code, record)
            }
            promise
                .then(saved => {
                    this.$toast.success(this.$t('referential.records.' + (record.creation ? 'created' : 'updated'), {code: record.code}))
                    this.datasetRecordSearchKey += 1
                })
                .finally(() => this.clearRecordDialog());
        },
        deleteRecord(record) {
            DatasetAPI.deleteRecord(this.dataset.code, record.code)
                .then(_ => {
                    this.$toast.warning(this.$t('referential.records.deleted', {code: record.code}))
                    this.datasetRecordSearchKey += 1
                })
                .finally(() => this.clearRecordDialog());
        },
        setTotalElements(total) {
            this.totalElements = total
        },
        setFilter(filter) {
            this.filter = filter;
        },
        deleteFilteredRecords() {
            this.$root.$confirm(this.$t('referential.dataset.records.mass-deletions-msg'),
                this.$t('referential.dataset.records.confirm-mass-deletions', {total: this.totalElements}),
                this.$t('referential.records.i-known-what-i-m-doing'))
                .then(confirm => {
                    if (confirm) {
                        this.openProperty = false;
                        DatasetAPI.massDeletions(this.dataset.code, this.filter)
                            .then(count => {
                                this.$toast.warning(`${count} records deleted`)
                                this.datasetRecordSearchKey += 1
                            })
                    }
                });
        },
        exportDatasetRecords() {
            this.loading = true
            this.loadingText = this.$t('referential.records.loading-export', {code: this.dataset.code})
            DatasetAPI.exportRecords(this.dataset.code)
                .then(blob => {
                    const url = window.URL.createObjectURL(blob)
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', `dataset-${this.dataset.code}-records.csv`)
                    document.body.appendChild(link)
                    link.click()
                    link.remove()
                })
                .finally(() => this.clearLoading())
        },
        importDatasetRecords(importData) {
            this.openImportRecords = false
            this.loading = true
            this.loadingText = this.$t('referential.records.loading-import', {code: this.dataset.code})
            DatasetAPI.importRecords(this.dataset.code, importData.file, importData.mode)
                .then(response => {
                    this.$toast.success(this.$t('referential.records.import.success-msg'))
                    this.datasetRecordSearchKey += 1
                })
                .finally(() => this.clearLoading())
        },
        clearLoading() {
            this.loading = false;
            this.loadingText = null;
        }
    }
}
</script>