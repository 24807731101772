<template>
  <v-card width="1024px" class="mx-auto">
    <v-toolbar dark color="primary" dense flat>
      <v-toolbar-title class="white--text">
        <span v-if="property.creation" v-html="$t('catalog.variable.properties.new-title', {parent: parent})" />
        <span v-else v-html="$t('catalog.variable.properties.edit-title', {name: property.name})" />
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <CodeTextField v-model="property.name" :edit="true" class="mb-8"/>
      <v-text-field v-model="property.label" :label="$t('common.label')" variant="outlined" bg-color="white"/>
      <ValueOrFormula :label="$t('common.value')" v-model="property.valueOrFormula" :edit="true" type="string"></ValueOrFormula>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn color="error" @click="cancel">Cancel</v-btn>
      <v-btn color="primary" @click="save">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import CodeTextField from "@/components/common/CodeTextField.vue";
import ValueOrFormula from "@/components/catalog/ValueOrFormula.vue";

export default {
  name: 'Property',
  components: {ValueOrFormula, CodeTextField},
  props: {
    /** @type {Property} */ property: {},
    parent: String
  },
  data: () => ({
    editorOptions: {
      highlightActiveLine: false,
      highlightGutterLine: false,
      minLines: 4,
      maxLines: 20
    }
  }),
  methods: {
    cancel() {
      this.$emit("canceled");
    },
    save() {
      this.$emit("saved", this.property)
    }
  }
}
</script>