<template>
  <v-dialog :model-value="loading" persistent>
    <v-card color="primary" width="512px" class="mx-auto">
      <v-card-text>
        <span v-html="text ? text : $t('common.loading')" />
        <v-progress-linear indeterminate height="8" color="white" class="my-4"></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>

export default {
  name: 'Loading',
  props: {
    loading: null,
    text: null
  }
}
</script>