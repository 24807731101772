<template>
    <v-app v-if="getCurrentModule">
        <div v-if="isAuthenticated">
            <v-app-bar color="primary">
                <v-app-bar-nav-icon variant="text" @click="drawer = !drawer"></v-app-bar-nav-icon>
                <v-app-bar-title>
                    <v-row class="pa-0 ma-0">
                        <img :src="'/' + getCurrentModule() + '-color.png'" alt="catalog" width="32">
                        <span class="font-weight-medium ml-2">
              RuleShake <span class="font-weight-light">{{ moduleName }}</span>
            </span>
                    </v-row>
                </v-app-bar-title>
                <v-spacer/>
                <Docs/>
                <div style="width: 100px">
                    <v-select v-model="$i18n.locale" :items="languages" variant="outlined" density="compact"
                              class="mt-4 mr-4" />
                </div>
                <UserMenu/>
            </v-app-bar>
            <v-navigation-drawer permanent :rail="drawer">
                <v-list density="compact">
                    <v-list-item v-for="item in menus" :prepend-icon="item.icon" :title="$t(item.label)"
                                 :value="item.link"
                                 @click="navigate(item)" :active="isCurrentModule(item)"/>
                </v-list>
            </v-navigation-drawer>
        </div>
        <v-main>
            <v-container style="max-width: 1440px">
                <router-view :key="$route.fullPath"/>
            </v-container>
        </v-main>
        <v-footer app absolute color="secondary">
            <v-row justify="center" no-gutters>
                <ButtonWithTooltip v-if="isAuthenticated" class="mx-2" v-for="item in menus" :icon="item.icon"
                                   :tooltip-text="$t(item.label)" @clicked="navigate(item)"/>
                <v-col cols="12" class="text-center">
                    <span>{{ new Date().getFullYear() }} — <strong>RuleShake</strong></span>
                </v-col>
            </v-row>
        </v-footer>
    </v-app>
    <ConfirmDialog ref="confirm"></ConfirmDialog>
    <v-snackbar v-model="snackbar" :color="snackbarColor" timeout="3000">{{ snackbarText }}</v-snackbar>
</template>

<script>
import {useFavicon, useTitle} from '@vueuse/core'
import {useRoute, useRouter} from "vue-router";
import {useAuth0} from "@auth0/auth0-vue";
import ConfirmDialog from "@/components/common/ConfirmDialog.vue";
import ButtonWithTooltip from "@/components/common/ButtonWithTooltip.vue";
import {computed, ref, watch} from "vue";
import UserMenu from "@/components/common/UserMenu.vue";
import Docs from "@/components/common/Docs.vue";
import {useDisplay} from "vuetify";

export default {
    name: 'App',
    components: {
        Docs,
        UserMenu,
        ButtonWithTooltip,
        ConfirmDialog
    },
    data: function () {
        return {
            snackbar: false,
            snackbarText: "",
            snackbarColor: "",
            menus: [
                {
                    label: 'menu.studio-home',
                    link: 'home',
                    icon: 'mdi-pencil-ruler'
                },
                {
                    label: 'menu.catalog',
                    link: 'catalog',
                    icon: 'mdi-notebook-outline'
                },
                {
                    label: 'menu.referential',
                    link: 'referential',
                    icon: 'mdi-layers-triple-outline'
                },
                {
                    label: 'menu.runner',
                    link: 'runner',
                    icon: 'mdi-turbine'
                },
                // {
                //   label: 'menu.batch',
                //   link: 'batch',
                //   icon: 'mdi-chart-donut-variant'
                // },
                // {
                //   label: 'menu.contents',
                //   link: 'contents',
                //   icon: 'mdi-tangram'
                // },
                {
                    label: 'menu.api',
                    link: 'api',
                    icon: 'mdi-api'
                },
                {
                    label: 'menu.about',
                    link: 'about',
                    icon: 'mdi-information-outline'
                }
            ]
        }
    },
    mounted() {
        this.$root.$confirm = this.$refs.confirm.open
        this.$root.$snackbar = this.showSnackbar;
    },
    setup() {
        const route = useRoute();
        const moduleName = computed(() => {
            let name = route.meta.module;
            if (!name) {
                return ""
            }
            return name[0].toUpperCase() + name.slice(1)
        })

        const icon = useFavicon()
        const title = useTitle()
        watch(
            () => route.fullPath,
            async () => {
                if (route.meta.module) {
                    icon.value = '/' + route.meta.module + '-color.png';
                    title.value = 'RuleShake ' + moduleName.value
                }
            }
        );

        const {isAuthenticated, isLoading} = useAuth0();
        let router = useRouter();
        router.isReady()
            .then(() => {
                if (!isLoading.value && !isAuthenticated.value && route.name !== 'login') {
                    router.push("login")
                }
            })
        const {lgAndDown} = useDisplay();
        const drawer = ref(lgAndDown.value)
        return {isAuthenticated, drawer}
    },
    computed: {
        moduleName() {
            let name = this.getCurrentModule();
            if (!name) {
                return ""
            }
            return name[0].toUpperCase() + name.slice(1)
        },
        languages() {
            return Object.keys(this.$i18n.messages);
        }
    },
    methods: {
        showSnackbar(text, color) {
            this.snackbarText = text;
            this.snackbarColor = color
            this.snackbar = true;
        },
        navigate(item) {
            this.$router.push({name: item.link})
        },
        isCurrentModule(item) {
            if (item && this.$route.name) {
                return item.link === this.getCurrentModule();
            }
            return false
        },
        getCurrentModule() {
            return this.$route.meta.module;
        }
    }
}
</script>
