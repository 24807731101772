<template>
    <v-expansion-panels class="mt-4" v-model="panel">
        <v-expansion-panel value="publications">
            <v-expansion-panel-title>
        <span class="text-h6 font-weight-medium">
          {{ $tc('catalog.publication.label', 2) }}
          ({{ publications && publications.length > 0 ? publications.length : 0 }})
        </span>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
                <v-card-text>
                    <div v-if="publications && publications.length > 0">
                        <v-row class="border my-1 pa-2 bg-primary" dense>
                            <v-col cols="1">
                                <span class="text-subtitle-1 font-weight-bold">{{ $t('common.version') }}</span>
                            </v-col>
                            <v-col cols="3">
                                <span class="text-subtitle-1 font-weight-bold">{{ $t('common.start-date') }}</span>
                            </v-col>
                            <v-col cols="3">
                                <span class="text-subtitle-1 font-weight-bold">{{ $t('common.end-date') }}</span>
                            </v-col>
                            <v-col cols="3">
                                <span class="text-subtitle-1 font-weight-bold">{{ $t('common.status') }}</span>
                            </v-col>
                        </v-row>
                        <div v-for="element in publications">
                            <v-row align="center" style="cursor: pointer" class="border pa-2 bg-grey-lighten-5" dense>
                                <v-col cols="1">
                                    <strong class="ml-2" style="font-family: Consolas, Menlo, Courier, monospace; font-size: medium">
                                        {{ element.version }}
                                    </strong>
                                </v-col>
                                <v-col cols="3">
                                    <span class="text-subtitle-1 font-weight-regular">
                                        {{ element.availability.start  }}
                                    </span>
                                </v-col>
                                <v-col cols="3">
                                    <span class="text-subtitle-1 font-weight-regular font-italic">
                                        {{ element.availability.end }}
                                    </span>
                                </v-col>
                                <v-col cols="3">
                                    <v-chip color="blue" label>
                                        <span style="font-family: monospace, serif">
                                            {{ $t('catalog.publication.status.' + element.publicationStatus.toLowerCase()) }}
                                        </span>
                                    </v-chip>
                                </v-col>
                                <v-col class="d-flex flex-row justify-end align-center">
                                    <v-progress-circular v-if="element.changingStatus" indeterminate color="primary" class="mr-6 mb-1"></v-progress-circular>
                                    <StateSelector v-else :model-value="element.executionStatus" :collection-code="collectionCode" @changed="newState => changeState(element, newState)"/>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </v-card-text>
            </v-expansion-panel-text>
        </v-expansion-panel>
    </v-expansion-panels>
</template>
<script setup>
import {ref} from "vue";
import ButtonWithTooltip from "@/components/common/ButtonWithTooltip.vue";
import StateSelector from "@/components/runner/StateSelector.vue";
import CollectionAPI from "@/api/runner/CollectionAPI";

const props = defineProps({
    collectionCode: String,
    publications: Array
})
const panel = ref(['publications'])
const changeState = (publication, newState) => {
    publication.changingStatus = true;
    CollectionAPI.changeState(publication.code, publication.version, newState)
      .then(result => {
          publication.executionStatus = newState
      })
      .finally(() => publication.changingStatus = false)

}
</script>