import {handleJson, securedFetch} from "@/api/ApiUtil";

export default {

    /**
     * Get swagger spec
     * @param {string} module
     */
    getSwaggerSpec(module) {
        return securedFetch(`/${module}-api/v3/api-docs`)
            .then(response => handleJson(response));
    },

    /**
     * Get state
     * @param {string} module
     * @returns {Promise<Map<string, string>>}
     */
    getState(module) {
        return securedFetch(`/${module}-api/state`)
            .then(response => handleJson(response));
    }
}