<template>
  <v-row class="my-4 mx-2" align="center">
    <img v-if="code" :src="'/' + code + '-color.png'" alt="catalog" width="64">
    <span class="text-h3 ml-3 font-weight-medium">RuleShake <span class="font-weight-light">{{ moduleName }}</span></span>
  </v-row>
  <v-divider class="my-8"></v-divider>
</template>
<script>
export default {
  name: 'ModuleHeader',
  props: {
    code: null,
  },
  data: () => ({
    tooltip: false
  }),
  computed: {
    moduleName() {
      return this.code[0].toUpperCase() + this.code.slice(1)
    }
  },
  methods: {
    help() {
      this.$router.push({ path: 'about', query: { module: this.code }})
    }
  }
}
</script>