<template>
    <Components :components="collections" :enable-tags="true" :component-label="$tc('catalog.collection.label', 1)"
                :create-component-label="$t('catalog.collection.create-or-import')" :allow-creation="true"
                @create="createCollection = true" @navigate="navigateToCollection"/>
    <Loading :loading="loading" :text="$t('catalog.collection.loading-collections')"/>
    <v-dialog v-model="createCollection" persistent>
        <CreateComponent type='collection' :show-import-mode="true"
                         @canceled="createCollection = false" @saved="doCreateCollection" @imported="doImportCollection">
            <template v-slot:form>
                <v-card-text>
                    <CodeTextField v-model="collection.code" :edit="true" class="mb-6"/>
                    <v-text-field v-model="collection.label" :label="$t('common.label')" variant="outlined"
                                  bg-color="white"/>
                    <v-combobox v-model="tags" :items="[]" :label="$t('catalog.collection.tags')" multiple chips
                                closable-chips hide-details variant="outlined"
                                style="background-color: white"></v-combobox>
                </v-card-text>
            </template>
        </CreateComponent>
    </v-dialog>
</template>
<script>
import CollectionAPI from "@/api/catalog/CollectionAPI.js"
import Components from "@/components/common/Components.vue";
import Loading from "@/components/common/Loading.vue";
import CreateComponent from "@/components/common/CreateComponent.vue";
import CodeTextField from "@/components/common/CodeTextField.vue";

export default {
    name: 'Collections',
    components: {CodeTextField, CreateComponent, Loading, Components},
    data: () => ({
        /** @type {Array<Collection>} */ collections: [],
        createCollection: false,
        /** @type {Collection} */ collection: {},
        /** @type {Array<string>} */ tags: [],
        filter: "",
        loading: true
    }),
    mounted() {
        CollectionAPI.list()
            .then(result => this.collections = result)
            .finally(() => this.loading = false)
    },
    methods: {
        doCreateCollection() {
            this.createCollection = false;
            this.collection.tags = this.tags;
            CollectionAPI.create(this.collection)
                .then(created => {
                    this.$toast.success(this.$t('catalog.collection.created', {code: created.code}))
                    this.navigateToCollection(created.code);
                })
        },
        doImportCollection(collectionImport) {
            this.createCollection = false;
            CollectionAPI.importCollection(collectionImport)
                .then(created => {
                    this.$toast.success(this.$t('catalog.collection.imported', {code: created.code}))
                    this.navigateToCollection(created.code);
                })
        },
        navigateToCollection(collectionCode) {
            this.$router.push({name: 'collection', params: {collectionCode: collectionCode}})
        }
    }
}
</script>