<template>
  <v-card width="896px" class="mx-auto">
    <v-toolbar dark color="primary" dense flat>
      <v-toolbar-title class="white--text">
        <span v-html="$t('referential.classifier.simulate', {code: classifier.code})" />
      </v-toolbar-title>
      <v-spacer/>
      <v-icon @click="close" class="mr-4">mdi-close</v-icon>
    </v-toolbar>
    <v-card-text class="my-4">
      <v-row align="center">
        <v-col cols="9">
          <DatasetRecordFinder v-model="record" :dataset-code="classifier.datasetCode"/>
        </v-col>
        <v-divider vertical class="mr-4" />
        <v-col>
          <v-btn color="primary" size="large" block @click="resolveClassifierRecord">{{ $t('common.resolve') }}</v-btn>
        </v-col>
      </v-row>
      <v-label v-if="lastRecord && !result" class="text-subtitle-1 mt-4">
        <span v-html="$t('referential.classifier.no-classifier-found', {record: lastRecord})" />
      </v-label>
      <div v-if="lastRecord && result" class="mt-4">
        <v-label class="text-subtitle-1">
          <span v-html="$t('referential.classifier.resolved-classifier',{record: lastRecord} )" />
        </v-label>
        <v-row class="border my-1 pa-2 bg-primary" dense>
          <v-col class="d-flex flex-row">
            <span class="text-subtitle-1 font-weight-bold" style="user-select: none">
              {{ $t('common.code') }}
            </span>
          </v-col>
          <v-col v-for="property in properties" class="d-flex flex-row">
            <span class="text-subtitle-1 font-weight-bold" style="user-select: none">
              {{ property.label }}
            </span>
          </v-col>
        </v-row>
        <v-row align="center" class="border pa-2 bg-grey-lighten-5" dense>
          <v-col>
            <strong style="font-family: Consolas, Menlo, Courier, monospace; font-size: medium">
              {{ result.code }}
            </strong>
          </v-col>
          <v-col v-for="property in properties">
            <span v-if="classifier.type === 'COMPOSITE'" class="text-subtitle-1 font-weight-regular font-italic">
              {{ result.properties[property.code].value }}
            </span>
            <span v-else class="text-subtitle-1 font-weight-regular font-italic">
              {{ result.value.value }}
            </span>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import DatasetRecordFinder from "@/components/referential/DatasetRecordFinder.vue";
import ClassifierAPI from "@/api/referential/ClassifierAPI.js";
export default {
  name: 'ClassifierSimulation',
  components: {DatasetRecordFinder},
  props: {
    classifier: null
  },
  data: () => ({
    record: null,
    panel: [],
    result: null,
    apiCalled: false,
    lastRecord: null
  }),
  computed: {
    properties() {
      if (this.classifier && this.classifier.type === 'COMPOSITE' && this.classifier.properties) {
        return this.classifier.properties
      }
      return [{
        code: 'value',
        label: this.$t('common.value')
      }]
    },
  },
  methods: {
    close() {
      this.$emit("closed")
    },
    resolveClassifierRecord() {
      this.result = null
      ClassifierAPI.resolveClassifierRecord(this.classifier.code, this.record)
          .then(response => this.result = response)
          .catch(error => {
            this.result = null
          })
          .finally(() => this.lastRecord = `${this.record}`)
    }
  }
}
</script>