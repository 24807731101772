<template>
  <div class="d-flex flex-column">
    <v-tabs v-model="tab" color="primary">
      <v-tab value="catalog">
        <v-icon start>mdi-notebook-outline</v-icon>
        Catalog
      </v-tab>
      <v-tab value="referential">
        <v-icon start>
          mdi-layers-triple-outline
        </v-icon>
        Referential
      </v-tab>
      <v-tab value="runner">
        <v-icon start>
          mdi-turbine
        </v-icon>
        Runner
      </v-tab>
<!--      <v-tab value="batch">
        <v-icon start>
          mdi-chart-donut-variant
        </v-icon>
        Batch
      </v-tab>-->
    </v-tabs>
    <v-window v-model="tab">
      <v-window-item value="catalog">
        <DisplaySwagger module="catalog"/>
      </v-window-item>
      <v-window-item value="referential">
        <DisplaySwagger module="referential"/>
      </v-window-item>
      <v-window-item value="runner">
        <DisplaySwagger module="runner"/>
      </v-window-item>
      <v-window-item value="batch">
      </v-window-item>
    </v-window>
  </div>

</template>
<script>
import DisplaySwagger from "@/components/common/DisplaySwagger.vue";

export default {
  name: 'Api',
  components: {DisplaySwagger},
  data: () => ({
    tab: 'catalog'
  }),
  created() {
    if (this.$route.query.module) {
      this.tab = this.$route.query.module
    }
  }
}
</script>