import {createStore} from 'vuex'
import AutocompleteAPI from "@/api/catalog/AutocompleteAPI";
import {getCollectionCode} from "@/util/index.js"

export default createStore({
    state() {
        return {
            currentCollection: null,
            currentVariable: null,
            autocompletionGraph: new Map(),
            selectedSimulationCase: null,
            currentDefinitions: []
        }
    },
    getters: {
        autocompletionGraph(state) {
            return state.autocompletionGraph.get(state.currentCollection)
        },
        getVariableDefinition: (state) => (variableReference) => {
            return state.currentDefinitions.get(variableReference)
        },
        getRootDefinitions(state) {
            return state.currentDefinitions.filter(variable => variable.reference.parent.indexOf("/") === -1)
        },
        getChildrenDefinitions: (state) => (parentReference) => {
            return state.currentDefinitions.filter(variable => variable.reference.parent === parentReference)
        },
        getNbInstanceVariable: (state) => (variableReference) => {
            return state.selectedSimulationCase.counts.get(variableReference)
        }
    },
    actions: {
        updateAutocompletion({commit, state}, code, force) {
            if (force || !state.autocompletionGraph.has(code)) {
                AutocompleteAPI.list(code)
                    .then(autocompletionGraph => commit('SET_AUTOCOMPLETION_GRAPH', {
                        code: code,
                        data: autocompletionGraph
                    }))
            }
        },
        setCurrentCollection({commit, dispatch}, code) {
            commit("SET_CURRENT_COLLECTION", code)
            dispatch("updateAutocompletion", code, false)
        },
        setCurrentVariable({commit, dispatch}, reference) {
            commit("SET_CURRENT_VARIABLE", reference)
            dispatch("setCurrentCollection", getCollectionCode(reference))
        },
        setSelectedSimulationCase({commit}, selectedCase) {
            if (selectedCase && selectedCase.inputs) {
                let references = new Set()
                selectedCase.inputs.forEach(input => {
                    let split = input.reference.split("/");
                    let tmp = null
                    split.forEach(code => {
                        if (tmp !== null) {
                            tmp += '/' + code
                        } else {
                            tmp = code
                        }
                        references.add(tmp)
                    })
                })
                let reduce = Array.from(references)
                    .map(reference => {
                        if (reference.endsWith("]")) {
                            return reference.substring(0, reference.lastIndexOf('['))
                        }
                        return reference
                    })
                    .reduce((a, b) => (a[b] = (a[b] || 0) + 1, a), {});
                selectedCase.counts = new Map(Object.entries(reduce))
            }
            commit("SET_SELECTED_SIMULATION_CASE", selectedCase)
        },
        setCurrentDefinitions({commit}, definitions) {
            commit("SET_CURRENT_DEFINITIONS", definitions.filter(variable => variable.nature === 'INGRESS'))
        }
    },
    mutations: {
        SET_CURRENT_COLLECTION(state, payload) {
            state.currentCollection = payload
        },
        SET_CURRENT_VARIABLE(state, payload) {
            state.currentVariable = payload
        },
        SET_AUTOCOMPLETION_GRAPH(state, payload) {
            state.autocompletionGraph.set(payload.code, payload.data)
        },
        SET_SELECTED_SIMULATION_CASE(state, payload) {
            state.selectedSimulationCase = payload
        },
        SET_CURRENT_DEFINITIONS(state, payload) {
            state.currentDefinitions = payload
        }
    }
})
