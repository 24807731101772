<template>
  <v-card-text>
    <v-card-title class="my-4 border border-opacity-50 border rounded">
      <v-label class="pl-2">{{ $t('common.filter') }}</v-label>
      <v-row align="center" class="pa-2">
        <v-col v-for="property in filterableProperties" :cols="filterablePropertyColSize">
          <v-autocomplete v-model="filter[property.code]" :label="property.label" :items="values[property.code]"
                          variant="outlined" hide-details bg-color="white" clearable/>
        </v-col>
      </v-row>
    </v-card-title>
    <div class="pb-4">
      <v-row class="border my-1 pa-2 bg-primary" dense>
        <v-col @click="doSort('code')" class="d-flex flex-row" style="cursor: pointer">
          <span class="text-subtitle-1 font-weight-bold" style="user-select: none">Code</span>
          <div v-if="pagination.sortField === 'code'">
            <v-icon v-if="pagination.sortDirection === 'ASC'">mdi-chevron-up</v-icon>
            <v-icon v-else>mdi-chevron-down</v-icon>
          </div>
        </v-col>
        <v-col v-for="property in properties" @click="doSort(property.code)" class="d-flex flex-row"
               style="cursor: pointer">
          <span class="text-subtitle-1 font-weight-bold" style="user-select: none">{{ property.label }}</span>
          <div v-if="pagination.sortField === property.code">
            <v-icon v-if="pagination.sortDirection === 'ASC'">mdi-chevron-up</v-icon>
            <v-icon v-else>mdi-chevron-down</v-icon>
          </div>
        </v-col>
        <v-col>
        </v-col>
      </v-row>
      <div v-for="element in records">
        <v-row align="center" style="cursor: pointer" @click="select(element)" dense
               :class="isSelected(element) ? 'border pa-2 bg-green-lighten-4' : 'border pa-2 bg-grey-lighten-5'">
          <v-col :class="element.disabled ? 'text-blue-grey-lighten-2' : ''">
            <strong style="font-family: Consolas, Menlo, Courier, monospace; font-size: medium">{{ element.code }}</strong>
          </v-col>
          <v-col v-for="property in properties" :class="element.disabled ? 'text-blue-grey-lighten-2' : ''">
            <span v-if="element.properties[property.code]" class="text-subtitle-1 font-weight-regular font-italic">
              {{ element.properties[property.code].value }}
            </span>
          </v-col>
          <v-col class="d-flex flex-row justify-end align-center">
            <v-chip v-if="element.disabled" color="warning" class="mr-2" label>
              <span style="font-family: monospace, serif">{{ $t('referential.dataset.records.disabled') }}</span>
            </v-chip>
          </v-col>
        </v-row>
      </div>
      <v-row align="center" class="mt-4 ml-1">
        <span class="text-subtitle-1" v-html="$t('referential.records.page', {nb: numberOfElements, total: totalElements})" />
        <v-spacer/>
        <v-pagination v-model="pagination.page" active-color="primary" variant="text" total-visible="5" :length="totalPages" />
      </v-row>
    </div>
  </v-card-text>
  <Loading :loading="loading" :text="$t('referential.records.loading', {code: datasetCode})" />
</template>
<script>
import draggable from "vuedraggable";
import DatasetAPI from "@/api/referential/DatasetAPI";
import Loading from "@/components/common/Loading.vue";

export default {
  name: 'DatasetRecordSearch',
  components: {Loading, draggable},
  props: {
    datasetCode: null
  },
  data: () => ({
    loading: false,
    dataset: {},
    records: [],
    values: {},
    filter: {},
    pagination: {
      page: 1,
      sortField: null,
      sortDirection: 'ASC',
      size: 10
    },
    totalPages: null,
    totalElements: null,
    numberOfElements: null,
    selected: null
  }),
  created() {
    DatasetAPI.find(this.datasetCode)
        .then(result => {
          this.dataset = result
          this.search()
        })
  },
  unmounted() {
    this.dataset = null
    this.selected = null
    this.records = []
    this.values = {}
    this.filter = {}
  },
  watch: {
    filter: {
      deep: true,
      handler(val) {
        this.pagination.page = 1
        this.$emit("filter", val);
        this.search()
      }
    },
    pagination: {
      deep: true,
      handler(val) {
        this.search()
      }
    }
  },
  computed: {
    properties() {
      if (this.dataset && this.dataset.properties) {
        return this.dataset.properties
            .filter(v => v.showInTable)
            .sort((a, b) => (a.orderIndex > b.orderIndex) ? 1 : -1)
      }
      return []
    },
    filterableProperties() {
      if (this.dataset && this.dataset.properties) {
        return this.dataset.properties
            .filter(v => v.showInFilter)
            .sort((a, b) => (a.orderIndex > b.orderIndex) ? 1 : -1)
      }
      return []
    },
    filterablePropertyColSize() {
      return Math.max(3, 12 / this.filterableProperties.length)
    }
  },
  methods: {
    select(record) {
      this.selected = record
      this.$emit("selected", record);
    },
    search() {
      this.loading = true
      const filter = {}
      Object.assign(filter, this.filter, this.pagination)
      DatasetAPI.searchRecords(this.dataset.code, filter)
          .then(result => {
            this.records = result.content
            this.totalPages = result.totalPages
            this.totalElements = result.totalElements
            this.numberOfElements = result.numberOfElements
            this.$emit("totalElements", this.totalElements);
            DatasetAPI.propertiesValues(this.dataset.code, this.filter)
                .then(values => {
                  this.values = values
                  this.loading = false
                })
          })
    },
    isSelected(record) {
      if (record && this.selected) {
        return record.code === this.selected.code
      }
      return false
    },
    doSort(property) {
      const currentSortField = this.pagination.sortField
      const currentSortDirection = this.pagination.sortDirection
      if (currentSortField === property) {
        if (currentSortDirection === 'ASC') {
          this.pagination.sortDirection = 'DESC'
        } else {
          this.pagination.sortDirection = 'ASC'
        }
      } else {
        this.pagination.sortDirection = 'ASC'
      }
      this.pagination.sortField = property
    }
  }
}
</script>