<template>
  <v-card width="720px" min-height="420px" class="mx-auto">
    <v-toolbar dark color="primary" dense flat>
      <v-toolbar-title class="white--text">
        <span v-html="$t('catalog.variable.duplicate.title', {label: toDuplicate.label})"/>
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <CodeTextField v-model="targetCode" :edit="true" class="mb-6" :label="$t('catalog.variable.duplicate.new-code')"/>
      <v-text-field v-model="targetLabel" :label="$t('catalog.variable.duplicate.new-label')" variant="outlined"
                    bg-color="white"/>
      <v-select v-model="targetCollection" :items="collections" :item-title="collectionItemTitle"
                :label="$t('catalog.variable.duplicate.target-collection')" variant="outlined" bg-color="white"
                return-object></v-select>
      <v-select v-model="targetParent" :items="composites" :label="$t('catalog.variable.duplicate.target-parent')"
                variant="outlined" bg-color="white"></v-select>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn color="error" @click="cancel">{{ $t('common.cancel') }}</v-btn>
      <v-btn color="primary" @click="duplicate">{{ $t('common.duplicate') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import CodeTextField from "@/components/common/CodeTextField.vue";
import CollectionAPI from "@/api/catalog/CollectionAPI";
import VariableAPI from "@/api/catalog/VariableAPI";

export default {
  name: "DuplicateVariable",
  components: {CodeTextField},
  props: {
    /** @type {Variable} */ toDuplicate: Object
  },
  data: () => ({
    collections: [],
    composites: [],
    targetCode: null,
    targetLabel: null,
    targetCollection: null,
    targetParent: null
  }),
  mounted() {
    CollectionAPI.list()
        .then(result => this.collections = result)
  },
  watch: {
    targetCollection: function handleEdit(collection) {
      this.targetParent = null
      this.composites = []
      VariableAPI.getCompositesVariable(collection.code)
          .then(result => {
            this.composites.push(collection.code)
            result.forEach(e => this.composites.push(e))
          })
    }
  },
  methods: {
    cancel() {
      this.$emit("canceled");
    },
    duplicate() {
      const variable = {
        reference: {
          parent: this.targetParent,
          code: this.targetCode
        },
        label: this.targetLabel,
        type: this.toDuplicate.type
      }
      this.$emit("duplicated", variable)
    },
    collectionItemTitle(collection) {
      return `${collection.code} - ${collection.label}`
    }
  }
}
</script>