import {handleJson, securedFetch} from "@/api/ApiUtil";

/**
 * Autocompletion definition
 * @typedef {Object} Autocompletion
 * @property {string} name
 * @property {string} caption
 * @property {string} description
 * @property {boolean} multiple
 * @property {'STRING'|'COMPOSITE'|'BOOLEAN'|'NUMBER'|'DATE'} type
 * @property {'CLASS'|'METHOD'|'VARIABLE'} scope
 * @property {Array<String>} properties
 * @property {Array<Autocompletion>} children
 * @property {Map<string, Autocompletion>} parameters
 */
export default {

    /**
     * list autocompletion for a collection
     * @param {string} collectionCode
     * @return {Promise<Array<Autocompletion>>}
     * */
    list(collectionCode) {
        return securedFetch("/catalog-api/v1/autocompletion/" + collectionCode)
            .then(response => handleJson(response))
    }

}