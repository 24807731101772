/**
 * Collection definition
 * @typedef {Object} Collection
 * @property {string} code
 * @property {string} label
 * @property {Array<string>} tags
 * @property {Map<string, string>} metadata
 */

/**
 * Collection export definition
 * @typedef {Object} CollectionExport
 * @property {string} code
 * @property {string} label
 * @property {Array<string>} tags
 * @property {Map<string, string>} metadata
 * @property {Array<Variable>} variables
 */

/**
 * Collection import definition
 * @typedef {Object} CollectionImport
 * @property {string} code
 * @property {string} label
 * @property {Array<string>} tags
 * @property {Map<string, string>} metadata
 * @property {Array<Variable>} variables
 * @property {'CREATE'|'OVERRIDE'|'MERGE'} mode
 */

import {call} from "@/api/ApiUtil";

export default {

    /**
     * list all collections
     * @return {Promise<Array<Collection>>}
     * */
    list() {
        return call("/catalog-api/v1/collections", {
            responseFormat: 'json',
            action: {
                key: 'catalog.collection.actions.list'
            }
        })
    },

    /**
     * find a collection by code
     * @param {string} collectionCode
     * @return {Promise<Collection>}
     */
    find(collectionCode) {
        return call(`/catalog-api/v1/collections/${collectionCode}`, {
            responseFormat: 'json',
            action: {
                key: 'catalog.collection.actions.find',
                params: {
                    code: collectionCode
                }
            }
        })
    },

    /**
     * Update collection
     * @param {Collection} collection
     * @return {Promise<Collection>}
     */
    update(collection) {
        return call(`/catalog-api/v1/collections/${collection.code}`, {
            responseFormat: 'json',
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(collection),
            action: {
                key: 'catalog.collection.actions.update',
                params: {
                    code: collection.code
                }
            }
        })
    },

    /**
     * Delete collection
     * @param {string} collectionCode
     * @return {Promise<boolean>}
     */
    delete(collectionCode) {
        return call(`/catalog-api/v1/collections/${collectionCode}`, {
            responseFormat: 'ignore',
            method: 'DELETE',
            action: {
                key: 'catalog.collection.actions.delete',
                params: {
                    code: collectionCode
                }
            }
        })
    },

    /**
     * Create collection
     * @param {Collection} collection
     * @return {Promise<Collection>}
     */
    create(collection) {
        return call("/catalog-api/v1/collections", {
            responseFormat: 'json',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(collection),
            action: {
                key: 'catalog.collection.actions.create',
                params: {
                    code: collection.code
                }
            }
        })
    },

    /**
     * Export collection
     * @param {string} collectionCode
     * @return {Promise<CollectionExport>}
     */
    exportCollection(collectionCode) {
        return call(`/catalog-api/v1/collections/${collectionCode}/export`, {
            responseFormat: 'json',
            action: {
                key: 'catalog.collection.actions.export',
                params: {
                    code: collectionCode
                }
            }
        })
    },

    /**
     * Import collection (override if exists)
     * @param {CollectionImport} collectionImport
     * @return {Promise<Collection>}
     */
    importCollection(collectionImport) {
        return call("/catalog-api/v1/collections/import", {
            responseFormat: 'json',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(collectionImport),
            action: {
                key: 'catalog.collection.actions.import'
            }
        })
    },

    simulateCollection(collectionCode, variables) {
        return call(`/catalog-api/v1/collections/${collectionCode}/simulations`, {
            responseFormat: 'json',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(variables),
            action: {
                key: 'catalog.collection.actions.simulate',
                params: {
                    code: collectionCode
                }
            }
        })
    },

    /**
     * Duplicate existing collection
     * @param {string} collectionCode
     * @param {Collection} duplicateInfos
     */
    duplicateCollection(collectionCode, duplicateInfos) {
        return call(`/catalog-api/v1/collections/${collectionCode}/duplicate`, {
            responseFormat: 'json',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(duplicateInfos),
            action: {
                key: 'catalog.collection.actions.duplicate',
                params: {
                    code: collectionCode
                }
            }
        })

    }

}