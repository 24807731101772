console.log("Starting application ...")

import {createApp} from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import i18n from "./plugins/i18n";
import {loadFonts} from './plugins/webfontloader'
import router from "./router"
import store from "./store";
import JsonViewer from 'vue-json-viewer'
import Toaster from "@meforma/vue-toaster";
import { auth0 } from './plugins/auth0';

loadFonts().then(r => console.log("Fonts loaded"))

let app = createApp(App)
    .use(store)
    .use(vuetify)
    .use(i18n)
    .use(Toaster, {
        position: "top-right",
        duration: 5000
    })
    .use(JsonViewer);
app
    .use(router, app)
    .use(auth0)
    .mount('#app')

export default app
