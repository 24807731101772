<template>
  <div v-if="variable.type === 'COMPOSITE'" class="mt-2">
    <v-expansion-panels v-if="insideExpansionPanel" class="mb-4">
      <v-expansion-panel>
        <v-expansion-panel-title>
          <span class="text-subtitle-1 font-weight-medium">{{ variable.label }}</span>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <div v-if="variable.multiple" class="my-2">
            <v-card v-for="nbInstance in nbInstances" class="mt-4" elevation="2">
              <v-card-title>
                <v-row class="ma-2">
                  <span class="text-subtitle-1 font-weight-medium">{{ variable.label }} {{ nbInstance }}</span>
                  <v-spacer/>
                  <v-btn v-if="nbInstance === nbInstances" icon="mdi-delete" color="error" variant="icon" @click="removeInstance"></v-btn>
                </v-row>
              </v-card-title>
              <v-card-text>
                <SimulationVariable v-for="subVariable in subVariables(variable)" :variable="subVariable"
                                    :parent="computeVariableReference(nbInstance - 1)" @updated="handleUpdated"
                                    @instance-removed="handleInstanceRemoved"/>
              </v-card-text>
            </v-card>
            <v-row class="my-6" justify="center">
              <v-btn @click="nbInstances++" color="primary">
                {{ $t('catalog.simulation.add-new-variable-instance', {variable: variable.label}) }}
              </v-btn>
            </v-row>
          </div>
          <div v-else class="my-2">
            <SimulationVariable v-for="subVariable in subVariables(variable)" :variable="subVariable" :parent="computeVariableReference()"
                                @updated="handleUpdated" @instance-removed="handleInstanceRemoved"/>
          </div>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
    <div v-else-if="variable.multiple" class="ma-2">
      <v-card v-for="nbInstance in nbInstances" class="mt-4" elevation="2">
        <v-card-title>
          <v-row class="ma-2">
            <span class="text-subtitle-1 font-weight-medium">{{ variable.label }} {{ nbInstance }}</span>
            <v-spacer/>
            <v-btn v-if="nbInstance === nbInstances" icon="mdi-delete" color="error" variant="icon" @click="removeInstance"></v-btn>
          </v-row>
        </v-card-title>
        <v-card-text>
          <SimulationVariable v-for="subVariable in subVariables(variable)" :variable="subVariable" :parent="computeVariableReference(nbInstance - 1)"
                              @updated="handleUpdated" @instance-removed="handleInstanceRemoved"/>
        </v-card-text>
      </v-card>
      <v-row class="mt-6 mb-1" justify="center">
        <v-btn @click="nbInstances++" color="primary">
          {{ $t('catalog.simulation.add-new-variable-instance', {variable: variable.label}) }}
        </v-btn>
      </v-row>
    </div>
    <div v-else class="ma-2">
      <SimulationVariable v-for="subVariable in subVariables(variable)" :variable="subVariable" :parent="computeVariableReference()"
                          @updated="handleUpdated" @instance-removed="handleInstanceRemoved"/>
    </div>
  </div>
  <v-row v-else-if="variable.type === 'BOOLEAN'" align="center">
    <v-col cols="3">
      <span class="text-subtitle-1">{{ variable.label }}</span>
    </v-col>
    <v-col>
      <div v-for="index in values.keys()">
        <v-radio-group v-model="values[index]" :id="computeVariableReference(index)" inline @input="handleValue" hide-details mandatory>
          <v-radio :label="$t('common.yes')" :value="true"></v-radio>
          <v-radio :label="$t('common.no')" :value="false"></v-radio>
        </v-radio-group>
      </div>
    </v-col>
  </v-row>
  <v-row v-else-if="variable.type === 'RECORD'" align="center">
    <v-col cols="3">
      <span class="text-subtitle-1">{{ variable.label }}</span>
    </v-col>
    <v-col>
      <div v-for="index in values.keys()" :class="index > 0 ? 'mt-2' : ''">
        <DatasetRecordFinder v-model="values[index]" :dataset-code="variable.datasetCode"
                             @update:modelValue="handleValueByIndex(index, values[index])"/>
      </div>
      <v-btn v-if="variable.multiple" @click="addInstance" color="primary" variant="text">
        {{ $t('catalog.simulation.add-new-variable-instance', {variable: variable.label}) }}
      </v-btn>
    </v-col>
  </v-row>
  <v-row v-else align="center">
    <v-col cols="3">
      <span class="text-subtitle-1">{{ variable.label }}</span>
    </v-col>
    <v-col>
      <div v-for="index in values.keys()" :class="index > 0 ? 'mt-2' : ''">
        <v-select v-if="variable.validValues" v-model="values[index]" :items="variable.validValues" :clearable="index === values.length - 1"
                  :id="computeVariableReference(parent, variable, index)" variant="outlined" bg-color="white" hide-details
                  @update:modelValue="handleValueByIndex(index, values[index])"/>
        <v-text-field v-else v-model="values[index]" :id="computeVariableReference(index)" :type="variable.type"
                      :step="variable.pace?.value" :min="variable.minValue?.value" :max="variable.maxValue?.value"
                      variant="outlined" bg-color="white" hide-details @input="handleValue"></v-text-field>
      </div>

      <v-btn v-if="variable.multiple" @click="addInstance" color="primary" variant="text">
        {{ $t('catalog.simulation.add-new-variable-instance', {variable: variable.label}) }}
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import DatasetRecordFinder from "@/components/referential/DatasetRecordFinder.vue";

export default {
  name: "SimulationVariable",
  components: {DatasetRecordFinder},
  props: {
    parent: {
      type: String,
      default: ''
    },
    variable: {}
  },
  data: () => ({
    nbInstances: 1,
    values: [undefined]
  }),
  created() {
    this.computeValues()
  },
  watch: {
    selectedCase: function () {
      this.computeValues()
    }
  },
  computed: {
    insideExpansionPanel() {
      return this.variable.reference.parent.includes("/");
    },
    selectedCase() {
      return this.$store.state.selectedSimulationCase
    }
  },
  methods: {
    subVariables(composite) {
      return this.$store.getters.getChildrenDefinitions(composite.reference.parent + "/" + composite.reference.code)
          .sort((a, b) => (a.orderIndex > b.orderIndex) ? 1 : -1)
    },
    handleValue(e) {
      if (this.variable.multiple) {
        this.handleUpdated({
          reference: e.target.id,
          value: e.target.value,
          type: this.variable.type
        })
      } else {
        this.handleUpdated({
          reference: (this.parent ? this.parent + "/" : '') + this.variable.reference.code,
          value: e.target.value,
          type: this.variable.type
        })
      }
    },
    handleValueByIndex(index, value) {
      if (value) {
        this.handleUpdated({
          reference: this.computeVariableReference(index),
          value: value,
          type: this.variable.type
        })
      } else {
        this.removeInstance()
      }
    },
    addInstance() {
      this.nbInstances++;
      this.values[this.nbInstances - 1] = this.getValueFromInputs(this.nbInstances - 1)
    },
    removeInstance() {
      this.handleInstanceRemoved(this.computeVariableReference(this.nbInstances - 1));
      this.nbInstances--;
      this.values.pop()
    },
    handleUpdated(inputVariable) {
      this.$emit("updated", inputVariable)
    },
    handleInstanceRemoved(variableReference) {
      this.$emit("instance-removed", variableReference)
    },
    computeVariableReference(instance = undefined) {
      if (instance >= 0 && this.variable.multiple) {
        return (this.parent ? this.parent + "/" : '') + this.variable.reference.code + "[" + instance + "]"
      }
      return (this.parent ? this.parent + "/" : '') + this.variable.reference.code
    },
    getValueFromInputs(instance) {
      if (this.selectedCase && this.selectedCase.inputs) {
        let found = this.selectedCase.inputs.find(input => input.reference === this.computeVariableReference(instance));
        if (found) {
          return found.value
        }
      }
      return undefined
    },
    computeValues() {
      this.nbInstances = 1
      this.values = [this.variable.value?.value]
      if (this.selectedCase) {
        let variableReference = this.computeVariableReference();
        let nbInstanceVariableFromSelectedCase = this.$store.getters.getNbInstanceVariable(variableReference);
        this.nbInstances = nbInstanceVariableFromSelectedCase ? nbInstanceVariableFromSelectedCase : 1;
        [...Array(this.nbInstances).keys()].forEach(i => this.values[i] = this.getValueFromInputs(i))
      }
    }
  }
}
</script>