<template>
    <Loading :loading="loading" :text="$t('common.loading')" />
    <v-card v-if="module" class="mt-6 pt-4 px-6">
    <v-card-actions>
      <span class="text-h4 font-weight-bold">{{ module.toUpperCase() }} API Description</span>
      <v-spacer />
      <v-btn variant="flat" color="primary" @click="loadSwagger">{{ $t('common.reload') }}</v-btn>
    </v-card-actions>
    <v-divider class="mt-4"/>
    <v-card-text>
      <div class="swagger" :id="module + 'swagger'"></div>
    </v-card-text>
  </v-card>
</template>
<script>
import { SwaggerUIBundle } from "swagger-ui-dist"
import 'swagger-ui-dist/swagger-ui.css';
import SupportAPI from "@/api/common/SupportAPI.js";
import Loading from "@/components/common/Loading.vue";

export default {
  name: "DisplaySwagger",
    components: {Loading},
  props: {
    module: null
  },
  data: () => ({
    panel: [],
    loading: true,
  }),
  created() {
    this.loadSwagger()
  },
  methods: {
    loadSwagger() {
      SupportAPI.getSwaggerSpec(this.module)
          .then(spec => SwaggerUIBundle({
              spec: spec,
              dom_id: `#${this.module}swagger`,
              presets: [
                  SwaggerUIBundle.presets.apis,
                  SwaggerUIBundle.SwaggerUIStandalonePreset,
              ],
          }))
          .finally(() => this.loading = false)
    }
  }
}
</script>
