/**
 * Simulation case definition
 * @typedef {Object} SimulationCase
 * @property {string} code
 * @property {string} collectionCode
 * @property {Array} inputs
 */

import {call} from "@/api/ApiUtil";

export default {

    /**
     * Simulation collection
     * @param {string} collectionCode
     * @param {Array<Variable>} variables
     * @returns {Promise<*>}
     */
    simulateCollection(collectionCode, variables) {
        return call(`/catalog-api/v1/simulations/${collectionCode}`, {
            responseFormat: 'json',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(variables),
            action: {
                key: 'catalog.simulation.actions.simulate',
                params: {
                    code: collectionCode
                }
            }
        })
    },

    /**
     * list collection's simulation cases
     * @param {string} collectionCode
     * @returns {Promise<Array>}
     */
    listCases(collectionCode) {
        return call(`/catalog-api/v1/simulations/${collectionCode}/cases`, {
            responseFormat: 'json',
            action: {
                key: 'catalog.simulation.actions.list-cases',
                params: {
                    code: collectionCode
                }
            }
        })
    },

    /**
     * get case inputs
     * @param {string} collectionCode
     * @param {string} caseCode
     * @returns {Promise<SimulationCase>}
     */
    getCase(collectionCode, caseCode) {
        return call(`/catalog-api/v1/simulations/${collectionCode}/cases/${caseCode}`, {
            responseFormat: 'json',
            action: {
                key: 'catalog.simulation.actions.load-case',
                params: {
                    code: caseCode
                }
            }
        })
    },

    /**
     * save case
     * @param {SimulationCase} caseToSave
     */
    saveCase(caseToSave) {
        return call(`/catalog-api/v1/simulations/${caseToSave.collectionCode}/cases`, {
            responseFormat: 'json',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(caseToSave),
            action: {
                key: 'catalog.simulation.actions.save-case',
                params: {
                    code: caseToSave.code
                }
            }
        })
    },

    /**
     * delete case
     * @param {SimulationCase} caseToDelete
     */
    deleteCase(caseToDelete) {
        return call(`/catalog-api/v1/simulations/${caseToDelete.collectionCode}/cases/${caseToDelete.code}`, {
            responseFormat: 'ignore',
            method: 'DELETE',
            action: {
                key: 'catalog.simulation.actions.delete-case',
                params: {
                    code: caseToDelete.code
                }
            }
        })
    }

}