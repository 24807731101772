<template>
    <v-expansion-panels class="mt-4" v-model="panel">
        <v-expansion-panel value="records" eager>
            <v-expansion-panel-title>
        <span class="text-h6 font-weight-medium">
          {{ $t('referential.records.label') }} ({{ totalElements }})
        </span>
            </v-expansion-panel-title>
            <v-expansion-panel-text :key="classifierRecordSearchKey">
                <v-row class="mt-4 mr-4" align="center">
                    <v-spacer/>
                    <v-menu anchor="bottom end" rounded>
                        <template v-slot:activator="{ props }">
                            <v-btn v-bind="props">
                                {{ $t('common.actions') }}
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                        </template>
                        <v-list density="compact" class="mt-2">
                            <v-list-item prepend-icon="mdi-plus-thick" @click="doOpenRecord"
                                         :title="$t('referential.records.add')"/>
                            <v-list-item prepend-icon="mdi-publish" @click="openImportRecords = true"
                                         :title="$t('referential.records.import-from-csv')"/>
                            <v-list-item prepend-icon="mdi-download" @click="exportClassifierRecords"
                                         :title="$t('referential.records.download-as-csv')"/>
                        </v-list>
                    </v-menu>
                </v-row>
                <v-card-title class="mt-8 border border-opacity-50 border rounded">
                    <v-label class="pl-2">{{ $t('common.filter') }}</v-label>
                    <v-row align="center" class="pa-2">
                        <v-col v-for="property in properties" :cols="propertyColSize">
                            <v-select v-model="filter[property.code]" :label="property.label"
                                      :items="values[property.code]"
                                      variant="outlined" hide-details bg-color="white" clearable/>
                        </v-col>
                    </v-row>
                </v-card-title>
                <div class="py-6">
                    <v-row class="border my-1 pa-2 bg-primary" dense>
                        <v-col @click="doSort('code')" class="d-flex flex-row" style="cursor: pointer">
                            <span class="text-subtitle-1 font-weight-bold"
                                  style="user-select: none">{{ $t('common.code') }}</span>
                            <div v-if="pagination.sortField === 'code'">
                                <v-icon v-if="pagination.sortDirection === 'ASC'">mdi-chevron-up</v-icon>
                                <v-icon v-else>mdi-chevron-down</v-icon>
                            </div>
                        </v-col>
                        <v-col v-for="property in properties" @click="doSort(property.code)" class="d-flex flex-row"
                               style="cursor: pointer">
                            <span class="text-subtitle-1 font-weight-bold" style="user-select: none">
                                {{ property.label }}
                            </span>
                            <div v-if="pagination.sortField === property.code">
                                <v-icon v-if="pagination.sortDirection === 'ASC'">mdi-chevron-up</v-icon>
                                <v-icon v-else>mdi-chevron-down</v-icon>
                            </div>
                        </v-col>
                        <v-col>
                            <span class="text-subtitle-1 font-weight-bold" style="user-select: none">
                                {{ $t('referential.classifier.records.associated-dataset-keys') }}
                            </span>
                        </v-col>
                    </v-row>
                    <div v-for="element in records">
                        <v-row align="center" style="cursor: pointer"
                               :class="isSelected(element) ? 'border pa-2 bg-green-lighten-4' : 'border pa-2 bg-grey-lighten-5'"
                               dense
                               @click="select(element)">
                            <v-col :class="element.disabled ? 'text-blue-grey-lighten-2' : ''">
                                <strong style="font-family: Consolas, Menlo, Courier, monospace; font-size: medium">
                                    {{ element.code }}
                                </strong>
                            </v-col>
                            <v-col v-for="property in properties"
                                   :class="element.disabled ? 'text-blue-grey-lighten-2' : ''">
                                <span v-if="classifier.type === 'COMPOSITE'"
                                      class="text-subtitle-1 font-weight-regular font-italic">
                                  {{ element.properties[property.code].value }}
                                </span>
                                <span v-else class="text-subtitle-1 font-weight-regular font-italic">
                                    {{ element.value.value }}
                                </span>
                            </v-col>
                            <v-col>
                <span class="text-subtitle-1 font-weight-regular font-italic"
                      v-html="associatedDatasetRecordKeys(element)"/>
                            </v-col>
                        </v-row>
                    </div>
                    <v-row align="center" class="mt-4 ml-1">
                        <span class="text-subtitle-1"
                              v-html="$t('referential.records.page', {nb: numberOfElements, total: totalElements})"/>
                        <v-spacer/>
                        <v-pagination v-model="pagination.page" active-color="primary" variant="text" total-visible="5"
                                      :length="totalPages"/>
                    </v-row>
                </div>
            </v-expansion-panel-text>
        </v-expansion-panel>
    </v-expansion-panels>
    <v-dialog v-model="openRecord" persistent>
        <ClassifierRecord :classifier="classifier" :record="selectedRecord" @canceled="clearRecordDialog"
                          @saved="saveRecord" @deleted="deleteRecord"/>
    </v-dialog>
    <v-dialog v-model="openImportRecords" persistent>
        <ImportRecords @canceled="openImportRecords = false" @imported="importClassifierRecords"/>
    </v-dialog>
    <Loading :loading="loading" :text="loadingText"/>
</template>
<script>
import ClassifierRecord from "@/components/referential/ClassifierRecord.vue";
import ClassifierAPI from "@/api/referential/ClassifierAPI";
import draggable from "vuedraggable";
import ImportRecords from "@/components/referential/ImportRecords.vue";
import DatasetAPI from "@/api/referential/DatasetAPI";
import Loading from "@/components/common/Loading.vue";

export default {
    name: 'ClassifierRecords',
    components: {Loading, ImportRecords, ClassifierRecord, draggable},
    props: {
        classifier: {}
    },
    data: () => ({
        classifierRecordSearchKey: 0,
        openRecord: false,
        openImportRecords: false,
        panel: [],
        records: [],
        selectedRecord: {},
        values: {},
        filter: {},
        pagination: {
            page: 1,
            sortField: null,
            sortDirection: 'ASC',
            size: 10
        },
        totalPages: null,
        totalElements: '?',
        numberOfElements: null,
        loading: false,
        loadingText: null
    }),
    created() {
        this.clearRecordDialog()
        this.search()
    },
    computed: {
        properties() {
            if (this.classifier && this.classifier.type === 'COMPOSITE' && this.classifier.properties) {
                return this.classifier.properties
            }
            return [{
                code: 'value',
                label: this.$t('common.value')
            }]
        },
        propertyColSize() {
            return Math.max(3, 12 / this.properties.length)
        }
    },
    watch: {
        filter: {
            deep: true,
            handler(val) {
                this.pagination.page = 1
                this.search()
            }
        },
        pagination: {
            deep: true,
            handler(val) {
                this.search()
            }
        }
    },
    methods: {
        select(record) {
            this.selectedRecord = record
            this.openRecord = true
        },
        clearRecordDialog() {
            this.openRecord = false;
            this.selectedRecord = {
                creation: true,
                properties: {},
            };
            if (this.classifier.type === 'COMPOSITE' && this.classifier.properties) {
                this.classifier.properties.forEach(property => this.selectedRecord.properties[property.code] = {value: null})
            } else {
                this.selectedRecord.value = {value: null}
            }
        },
        saveRecord(record) {
            let promise;
            record.classifierCode = this.classifier.code
            record.type = this.classifier.type === 'COMPOSITE' ? 'COMPOSITE' : 'SIMPLE'
            if (record.creation) {
                promise = ClassifierAPI.addRecord(this.classifier.code, record)
            } else {
                promise = ClassifierAPI.updateRecord(this.classifier.code, record)
            }
            promise
                .then(saved => {
                    this.$toast.success(this.$t('referential.records.' + (record.creation ? 'created' : 'updated'), {code: record.code}))
                    this.search()
                })
                .finally(() => this.clearRecordDialog());
        },
        deleteRecord(record) {
            ClassifierAPI.deleteRecord(this.classifier.code, record.code)
                .then(_ => {
                    this.$toast.warning(this.$t('referential.records.deleted', {code: record.code}))
                    this.search()
                })
                .finally(() => this.clearRecordDialog());
        },
        isSelected(record) {
            if (record && this.selected) {
                return record.code === this.selected.code
            }
            return false
        },
        doSort(property) {
            const currentSortField = this.pagination.sortField
            const currentSortDirection = this.pagination.sortDirection
            if (currentSortField === property) {
                if (currentSortDirection === 'ASC') {
                    this.pagination.sortDirection = 'DESC'
                } else {
                    this.pagination.sortDirection = 'ASC'
                }
            } else {
                this.pagination.sortDirection = 'ASC'
            }
            this.pagination.sortField = property
        },
        search() {
            const filter = {}
            Object.assign(filter, this.filter, this.pagination)
            ClassifierAPI.searchRecords(this.classifier.code, filter)
                .then(result => {
                    this.records = result.content
                    this.totalPages = result.totalPages
                    this.totalElements = result.totalElements
                    this.numberOfElements = result.numberOfElements
                    ClassifierAPI.propertiesValues(this.classifier.code, this.filter)
                        .then(values => {
                            this.values = values
                        })
                })
        },
        associatedDatasetRecordKeys(record) {
            if (record && record.datasetRecordsKeys) {
                const tmp = record.datasetRecordsKeys.reduce((total, key) => {
                    total[key.propertyCode] = (total[key.propertyCode] || 0) + 1
                    return total
                }, {})
                return Object.keys(tmp).map(k => '<strong>' + tmp[k] + '</strong> ' + k).join(', ')
            }
            return 'None'
        },
        exportClassifierRecords() {
            this.loading = true
            this.loadingText = this.$t('referential.records.loading-export', {code: this.classifier.code})
            ClassifierAPI.exportRecords(this.classifier.code)
                .then(blob => {
                    const url = window.URL.createObjectURL(blob)
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', `classifier-${this.classifier.code}-records.csv`)
                    document.body.appendChild(link)
                    link.click()
                    link.remove()
                })
                .finally(() => this.clearLoading())
        },
        importClassifierRecords(importData) {
            this.openImportRecords = false
            this.loading = true
            this.loadingText = this.$t('referential.records.loading-import', {code: this.classifier.code})
            ClassifierAPI.importRecords(this.classifier.code, importData.file, importData.mode)
                .then(response => {
                    this.$toast.success(this.$t('referential.records.import.success-msg'))
                    this.search()
                })
                .finally(() => this.clearLoading())
        },
        clearLoading() {
            this.loading = false;
            this.loadingText = null;
        },
        doOpenRecord() {
            this.clearRecordDialog()
            this.openRecord = true
        }
    }
}
</script>