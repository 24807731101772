import {call} from "@/api/ApiUtil";

export default {

    listCollectionPublications(collectionCode) {
        return call(`/catalog-api/v1/collections/${collectionCode}/publications`, {
            responseFormat: 'json',
            action: {
                key: 'catalog.publication.actions.list',
                params: {
                    code: collectionCode,
                    type: 'collection'
                }
            }
        })
    },

    publishCollection(collectionCode, action) {
        return call(`/catalog-api/v1/collections/${collectionCode}/publications`, {
            responseFormat: 'json',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(action),
            action: {
                key: 'catalog.publication.actions.publish',
                params: {
                    code: collectionCode,
                    type: 'collection'
                }
            }
        })
    },
}