<template>
  <v-card width="720px" class="mx-auto">
    <v-toolbar dark color="primary" dense flat>
      <v-toolbar-title class="white--text">
        <span v-if="record.creation"
              v-html="$t('referential.records.new-title', {code: classifier.code})"/>
        <span v-else v-html="$t('referential.records.edit-title', {code: record.code})"/>
      </v-toolbar-title>
      <v-spacer/>
      <v-icon class="mr-4" @click="cancel">mdi-close</v-icon>
    </v-toolbar>
    <v-card-text>
      <CodeTextField v-model="record.code" :edit="record.creation" class="mb-6" :allow-start-by-number="true"/>
      <v-text-field v-if="classifier.type !== 'COMPOSITE'" v-model="record.value.value" :label="$t('common.value')"
                    :type="classifier.type" variant="outlined" bg-color="white"/>
      <div v-else>
        <div v-for="property in properties">
          <YesNoField v-if="property.type === 'BOOLEAN'" v-model="record.properties[property.code].value"
                      :label="property.label"/>
          <v-text-field v-else v-model="record.properties[property.code].value" :label="property.label"
                        :type="property.type" variant="outlined" bg-color="white"/>
        </div>
      </div>
      <v-card-title class="border border-opacity-50 border rounded">
        <v-label class="pl-2 mb-4">{{ $t('referential.classifier.records.associated-dataset-keys') }} :</v-label>
        <br />
        <v-row v-for="(datasetRecordKey, index) in record.datasetRecordsKeys" align="center">
          <v-col>
            <v-select v-model="datasetRecordKey.propertyCode" :items="keys" variant="outlined"
                      :label="$tc('common.key', 1)" dense hide-details bg-color="white"/>
          </v-col>
          <v-col>
            <v-autocomplete v-model="datasetRecordKey.propertyValue" :label="$t('common.value')" hide-details
                            variant="outlined" bg-color="white" :items="values[datasetRecordKey.propertyCode]"/>
          </v-col>
          <v-icon size="small" color="error" class="mr-2" @click="deleteDatasetRecordKey(index)">mdi-delete</v-icon>
        </v-row>
        <v-btn @click="addDatasetRecordKey"
               :class="record.datasetRecordsKeys && record.datasetRecordsKeys.length > 0 ? 'mt-2' : ''" variant="text"
               color="primary">
          {{ $t('referential.classifier.records.add-dataset-key') }}
        </v-btn>
      </v-card-title>
    </v-card-text>
    <v-card-actions>
      <v-btn v-if="!record.creation" color="error" @click="remove">{{ $t('common.delete') }}</v-btn>
      <v-spacer/>
      <v-btn color="error" @click="cancel">{{ $t('common.cancel') }}</v-btn>
      <v-btn color="primary" @click="save">{{ $t('common.save') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import CodeTextField from "@/components/common/CodeTextField.vue";
import YesNoField from "@/components/common/YesNoField.vue";
import DatasetRecordFinder from "@/components/referential/DatasetRecordFinder.vue";
import DatasetAPI from "@/api/referential/DatasetAPI";

export default {
  name: 'ClassifierRecord',
  components: {DatasetRecordFinder, YesNoField, CodeTextField},
  props: {
    /** @type {Record} */ record: {},
    /** @type {Classifier} */ classifier: {}
  },
  data: () => ({
    types: ['STRING', 'NUMBER', 'BOOLEAN', 'DATE'],
    values: {}
  }),
  created() {
    if (!this.record.datasetRecordsKeys) {
      this.record.datasetRecordsKeys = []
    }
    this.keys.forEach(key => DatasetAPI.propertyValues(this.classifier.datasetCode, key)
        .then(result => this.values[key] = result)
    )
  },
  computed: {
    properties() {
      if (this.classifier && this.classifier.type === 'COMPOSITE' && this.classifier.properties) {
        return this.classifier.properties
      }
      return []
    },
    keys() {
      if (this.classifier && this.classifier.keys) {
        return this.classifier.keys.map(k => k.propertyCode)
      }
      return []
    }
  },
  methods: {
    cancel() {
      this.$emit("canceled");
    },
    save() {
      this.record.datasetRecordsKeys = this.record.datasetRecordsKeys.filter(d => d.propertyCode !== null);
      this.$emit("saved", this.record)
    },
    remove() {
      this.$root.$confirm(this.$t('referential.records.delete-msg', {code: this.record.code}), this.$t('referential.classifier.records.confirm-delete-msg', {code: this.record.code}))
          .then(confirm => {
            if (confirm) {
              this.$emit("deleted", this.record)
            }
          })
    },
    addDatasetRecordKey() {
      this.record.datasetRecordsKeys.push({
        propertyCode: null,
        propertyValue: null
      })
    },
    deleteDatasetRecordKey(index) {
      this.record.datasetRecordsKeys.splice(index, 1)
    }
  }
}
</script>