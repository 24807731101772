/**
 * Classifier
 * @typedef {Object} Classifier
 * @property {string} code
 * @property {string} label
 * @property {'STRING'|'DATE'|'BOOLEAN'|'NUMBER'|'COMPOSITE'} type
 * @property {string} datasetCode
 * @property {Array<ClassifierKey>} keys
 * @property {Array<ClassifierProperty>} properties
 */

/**
 * Classifier property definition
 * @typedef {Object} ClassifierProperty
 * @property {string} code
 * @property {string} label
 * @property {'STRING'|'DATE'|'BOOLEAN'|'NUMBER'} type
 */

/**
 * Classifier key
 * @typedef {Object} ClassifierKey
 * @property {string} propertyCode
 * @property {number} orderIndex
 */

/**
 * Classifier Record
 * @typedef {Object} ClassifierRecord
 * @property {string} code
 * @property {string} classifierCode
 * @property {Map} properties
 */

/**
 * Classifier Record Page
 * @typedef {Object} ClassifierRecordPage
 * @property {Array<ClassifierRecord>} content
 * @property {number} totalPages
 * @property {number} totalElements
 * @property {number} numberOfElements
 */
import {call} from "@/api/ApiUtil";

export default {

    /**
     * list all datasets
     * @return {Promise<Array<Classifier>>}
     * */
    list() {
        return call("/referential-api/v1/classifiers", {
            responseFormat: 'json',
            action: {
                key: 'referential.classifier.actions.list'
            }
        })
    },

    /**
     * list all datasets
     * @param {string} datasetCode
     * @return {Promise<Array<Classifier>>}
     * */
    listByDataset(datasetCode) {
        return call(`/referential-api/v1/datasets/${datasetCode}/classifiers`, {
            responseFormat: 'json',
            action: {
                key: 'referential.dataset.actions.list-classifiers',
                params: {
                    code: datasetCode
                }
            }
        })
    },

    /**
     * Create classifier
     * @param {Classifier} classifier
     * @return {Promise<Classifier>}
     */
    create(classifier) {
        return call("/referential-api/v1/classifiers", {
            responseFormat: 'json',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(classifier),
            action: {
                key: 'referential.classifier.actions.create',
                params: {
                    code: classifier.code
                }
            }
        })
    },

    /**
     * Find classifier
     * @param {string} classifierCode
     * @returns {Promise<Classifier>}
     */
    find(classifierCode) {
        return call(`/referential-api/v1/classifiers/${classifierCode}`, {
            responseFormat: 'json',
            action: {
                key: 'referential.classifier.actions.find',
                params: {
                    code: classifierCode
                }
            }
        })
    },

    /**
     * Update classifier
     * @param {Classifier} classifier
     * @return {Promise<Classifier>}
     */
    update(classifier) {
        return call(`/referential-api/v1/classifiers/${classifier.code}`, {
            responseFormat: 'json',
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(classifier),
            action: {
                key: 'referential.classifier.actions.update',
                params: {
                    code: classifier.code
                }
            }
        })
    },

    /**
     * Delete classifier
     * @param {string} classifierCode
     * @return {Promise<boolean>}
     */
    delete(classifierCode) {
        return call(`/referential-api/v1/classifiers/${classifierCode}`, {
            responseFormat: 'ignore',
            method: 'DELETE',
            action: {
                key: 'referential.classifier.actions.delete',
                params: {
                    code: classifierCode
                }
            }
        })
    },

    /**
     * Add new property to a dataset
     * @param {string} classifierCode
     * @param {ClassifierProperty} property
     * @returns {Promise<Classifier>}
     */
    addProperty(classifierCode, property) {
        return call(`/referential-api/v1/classifiers/${classifierCode}/properties`, {
            responseFormat: 'json',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(property),
            action: {
                key: 'referential.classifier.actions.add-property',
                params: {
                    code: classifierCode
                }
            }
        })
    },

    /**
     * Update a property
     * @param {string} classifierCode
     * @param {ClassifierProperty} property
     * @returns {Promise<Classifier>}
     */
    updateProperty(classifierCode, property) {
        return call(`/referential-api/v1/classifiers/${classifierCode}/properties/${property.code}`, {
            responseFormat: 'json',
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(property),
            action: {
                key: 'referential.classifier.actions.update-property',
                params: {
                    code: classifierCode,
                    propertyCode: property.code
                }
            }
        })
    },

    /**
     * Delete a property
     * @param {string} classifierCode
     * @param {string} propertyCode
     * @returns {Promise<Classifier>}
     */
    deleteProperty(classifierCode, propertyCode) {
        return call(`/referential-api/v1/classifiers/${classifierCode}/properties/${propertyCode}`, {
            responseFormat: 'json',
            method: 'DELETE',
            action: {
                key: 'referential.classifier.actions.delete-property',
                params: {
                    code: classifierCode,
                    propertyCode: propertyCode
                }
            }
        })
    },

    /**
     * Add record to classifier
     * @param {string} classifierCode
     * @param {ClassifierRecord} record
     * @returns {Promise<ClassifierRecord>}
     */
    addRecord(classifierCode, record) {
        return call(`/referential-api/v1/classifiers/${classifierCode}/records`, {
            responseFormat: 'json',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(record),
            action: {
                key: 'referential.classifier.actions.add-record',
                params: {
                    code: classifierCode
                }
            }
        })
    },

    /**
     * Update record
     * @param {string} classifierCode
     * @param {ClassifierRecord} record
     * @returns {Promise<ClassifierRecord>}
     */
    updateRecord(classifierCode, record) {
        return call(`/referential-api/v1/classifiers/${classifierCode}/records/${record.code}`, {
            responseFormat: 'json',
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(record),
            action: {
                key: 'referential.classifier.actions.update-record',
                params: {
                    code: classifierCode,
                    recordCode: record.code
                }
            }
        })
    },

    /**
     * Delete a record
     * @param {string} classifierCode
     * @param {String} recordCode
     * @returns {Promise<* | boolean>}
     */
    deleteRecord(classifierCode, recordCode) {
        return call(`/referential-api/v1/classifiers/${classifierCode}/records/${recordCode}`, {
            responseFormat: 'ignore',
            method: 'DELETE',
            action: {
                key: 'referential.classifier.actions.delete-record',
                params: {
                    code: classifierCode,
                    recordCode: recordCode
                }
            }
        })
    },

    /**
     * Search records
     * @param {string} classifierCode
     * @param {Object} filter
     * @returns {Promise<ClassifierRecordPage>}
     */
    searchRecords(classifierCode, filter) {
        let query = Object.keys(filter)
            .filter(k => filter[k])
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(filter[k]))
            .join('&');
        let url = `/referential-api/v1/classifiers/${classifierCode}/records`
        if (query) {
            url += "?" + query
        }
        return call(url, {
            responseFormat: 'json',
            action: {
                key: 'referential.classifier.actions.search-records',
                params: {
                    code: classifierCode
                }
            }
        })
    },

    /**
     * Search values
     * @param {string} classifierCode
     * @param {Object} filter
     * @returns {Promise<Map>}
     */
    propertiesValues(classifierCode, filter) {
        let query = Object.keys(filter)
            .filter(k => filter[k])
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(filter[k]))
            .join('&');
        let url = `/referential-api/v1/classifiers/${classifierCode}/values`
        if (query) {
            url += "?" + query
        }
        return call(url, {
            responseFormat: 'json',
            action: {
                key: 'referential.classifier.actions.search-values',
                params: {
                    code: classifierCode
                }
            }
        })
    },

    /**
     * Resolve classifier
     * @param {string} classifierCode
     * @param {string} recordCode
     * @returns {Promise<ClassifierRecord>}
     */
    resolveClassifierRecord(classifierCode, recordCode) {
        return call(`/referential-api/v1/classifiers/${classifierCode}/resolve?recordCode=${recordCode}`, {
            responseFormat: 'json',
            action: {
                key: 'referential.classifier.actions.resolve',
                params: {
                    code: classifierCode
                }
            }
        })
    },

    /**
     * Export classifier records as CSV
     * @param {string} classifierRecord
     * @returns {Promise<Blob>}
     */
    exportRecords(classifierRecord) {
        return call(`/referential-api/v1/classifiers/${classifierRecord}/records/export`, {
            responseFormat: 'blob',
            method: 'POST',
            action: {
                key: 'referential.classifier.actions.export-records',
                params: {
                    code: classifierRecord,
                }
            }
        })
    },


    /**
     * Import classifier records from CSV
     * @param {string} classifierRecord
     * @param {File} file
     * @param {'MERGE'|'OVERRIDE'}mode
     * @returns {Promise<* | boolean>}
     */
    importRecords(classifierRecord, file, mode) {
        let formData = new FormData();
        formData.append( 'file', file );
        return call(`/referential-api/v1/classifiers/${classifierRecord}/records/import?mode=${mode}`, {
            responseFormat: 'ignore',
            method: 'POST',
            headers: {
                'Accept': 'multipart/form-data'
            },
            body: formData,
            action: {
                key: 'referential.classifier.actions.import-records',
                params: {
                    code: classifierRecord,
                }
            }
        })
    },
}