<template>
  <v-card color="white" elevation="0" border class="mb-6 pa-2 border-opacity-50">
    <v-row align="center">
      <v-col cols="5">
        <span class="text-subtitle-1 v-label ml-1">{{ label }}</span>
      </v-col>
      <v-col class="d-flex flex-row mr-2">
        <v-col class="pa-1">
          <v-btn :variant="yesValue ? 'flat': 'outlined'" color="primary" :active="yesValue" block @click="yes">{{ $t('common.yes') }}</v-btn>
        </v-col>
        <v-col class="pa-1">
          <v-btn :variant="noValue ? 'flat': 'outlined'" color="primary" :active="noValue" block @click="no" class="ml-2">{{ $t('common.no') }}</v-btn>
        </v-col>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  name: 'YesNoField',
  props: {
    label: null,
    modelValue: false,
    edit: false
  },
  data: () => ({
    yesValue: null,
    noValue: null
  }),
  created() {
    if (this.modelValue) {
      this.yesValue = true
    } else {
      this.noValue = true
    }
  },
  methods: {
    yes() {
      this.yesValue = true
      this.noValue = false
      this.$emit("update:modelValue", true);
    },
    no() {
      this.yesValue = false
      this.noValue = true
      this.$emit("update:modelValue", false);
    }
  }
}
</script>