<template>
  <v-expansion-panels class="mt-4" v-model="panel">
    <v-expansion-panel value="subVariables">
      <v-expansion-panel-title>
        <span class="text-h6 font-weight-medium">
          {{ $t('catalog.variable.provided-sub-variables.title') }}
          ({{ variables && variables.length > 0 ? variables.length : 0 }})
        </span>
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <v-card-text>
          <div>
            <v-row class="border my-1 pa-2 bg-primary" dense>
              <v-col cols="4">
                <span class="text-subtitle-1 font-weight-bold">{{ $t('common.code') }}</span>
              </v-col>
              <v-col cols="5">
                <span class="text-subtitle-1 font-weight-bold">{{ $t('common.label') }}</span>
              </v-col>
              <v-col>
                <span class="text-subtitle-1 font-weight-bold">{{ $t('catalog.variable.nature.label') }}</span>
              </v-col>
              <v-col>
                <span class="text-subtitle-1 font-weight-bold">{{ $t('catalog.variable.type.label') }}</span>
              </v-col>
            </v-row>
            <div v-for="element in variables">
              <v-row align="center" style="cursor: pointer" class="border pa-2 bg-grey-lighten-5" dense>
                <v-col cols="4">
                  <strong style="font-family: Consolas, Menlo, Courier, monospace; font-size: medium">{{ element.code }}</strong>
                </v-col>
                <v-col cols="5">
                  <span class="text-subtitle-1 font-weight-regular font-italic">{{ element.label }}</span>
                </v-col>
                <v-col>
                  <v-chip color="purple" label><span style="font-family: monospace, serif">{{ $t('catalog.variable.nature.' + element.nature.toLowerCase()) }}</span>
                  </v-chip>
                </v-col>
                <v-col>
                  <v-chip color="blue" label><span style="font-family: monospace, serif">{{ $t('catalog.variable.type.' + element.type.toLowerCase()) }}</span>
                  </v-chip>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-card-text>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import DatasetAPI from "@/api/referential/DatasetAPI";
import ClassifierAPI from "@/api/referential/ClassifierAPI";

export default {
  name: 'RecordSubVariables',
  props: {
    /**
     * @type Variable
     */
    variable: undefined
  },
  data: () => ({
    panel: ['subVariables'],
    variables: [],
    classifiers: new Map()
  }),
  created() {
    DatasetAPI.find(this.variable.datasetCode)
        .then(result => {
          result.properties.forEach(property => this.addSubVariable(property.code, property.label, property.type, 'PROVIDED'))
          ClassifierAPI.listByDataset(result.code)
              .then(result2 => {
                this.classifiers = new Map(result2.map(o => [o.code, o]))
                this.variable.classifiers?.sort((a, b) => a - b)
                    .forEach(classifierVar => {
                      const classifier = this.classifiers.get(classifierVar)
                      this.addSubVariable(classifier.code, classifier.label, classifier.type, 'CLASSIFIER')
                    })
              })
        })
  },
  methods: {
    addSubVariable(code, label, type, nature) {
      this.variables.push({
        code: code,
        label: label,
        type: type,
        nature: nature
      })
    }
  }
}
</script>