<template>
  <v-card width="720px" class="mx-auto">
    <v-toolbar dark color="primary" dense flat>
      <v-toolbar-title class="white--text">
        <span v-html="$t('catalog.collection.release-msg', {label: component.label})"/>
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col>
            <v-text-field v-model="startDate" :label="$t('common.start-date')"
                          :rules="[v => !!v || this.$t('validation.required', {label: $t('common.start-date')})]"
                          variant="outlined" type="date" bg-color="white" class="mt-3"/>
          </v-col>
          <v-col>
            <v-text-field v-model="startTime" :label="$t('common.start-time')"
                          :rules="[v => !!v || this.$t('validation.required', {label: $t('common.start-time')})]"
                          variant="outlined" type="time" bg-color="white" class="mt-3"/>
          </v-col>
        </v-row>
        <YesNoField v-model="doRelease" :label="$t('catalog.publication.create-release')" class="mt-2"/>
<!--        <YesNoField v-model="doDeploy" :label="$t('catalog.publication.deploy-to-runner')" class="mt-2"/>-->
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn color="error" @click="cancel">{{ $t('common.cancel') }}</v-btn>
      <v-btn color="primary" @click="save">
        {{
          doRelease ?
              (doDeploy ? $t('catalog.publication.release-and-deploy') : $t('common.release')) :
              (doDeploy ? $t('catalog.publication.publish-and-deploy') : $t('common.publish'))
        }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>

import YesNoField from "@/components/common/YesNoField.vue";

export default {
  name: "CreatePublication",
  components: {YesNoField},
  props: {
    component: {}
  },
  data: function () {
    return {
      valid: true,
      doDeploy: true,
      doRelease: false,
      startDate: null,
      startTime: '00:00'
    }
  },
  methods: {
    cancel() {
      this.$emit("canceled");
    },
    async save() {
      const {valid} = await this.$refs.form.validate()
      if (valid) {
        let publication = {
          deploy: this.doDeploy,
          release: this.doRelease,
          availability: {
            start: `${this.startDate}T${this.startTime}:00`
          }
        }
        this.$emit("saved", publication)
      }
    }
  }
}
</script>