/**
 * Dataset definition
 * @typedef {Object} Dataset
 * @property {string} code
 * @property {string} label
 * @property {Array<DatasetProperty>} properties
 */

/**
 * Dataset property definition
 * @typedef {Object} DatasetProperty
 * @property {number} orderIndex
 * @property {string} code
 * @property {string} label
 * @property {'STRING'|'DATE'|'BOOLEAN'|'NUMBER'} type
 * @property {boolean} showInFilter
 * @property {boolean} showInTable
 */

/**
 * Dataset Record
 * @typedef {Object} DatasetRecord
 * @property {string} code
 * @property {string} datasetCode
 * @property {Map} properties
 */

/**
 * Dataset Record Page
 * @typedef {Object} DatasetRecordPage
 * @property {Array<DatasetRecord>} content
 * @property {number} totalPages
 * @property {number} totalElements
 * @property {number} numberOfElements
 */

import {call, handleJson, handleVoid} from "@/api/ApiUtil";

export default {

    /**
     * list all datasets
     * @return {Promise<Array<Dataset>>}
     * */
    list() {
        return call("/referential-api/v1/datasets", {
            responseFormat: 'json',
            action: {
                key: 'referential.dataset.actions.list'
            }
        })
    },

    /**
     * find a dataset by code
     * @param {string} datasetCode
     * @return {Promise<Dataset>}
     */
    find(datasetCode) {
        return call(`/referential-api/v1/datasets/${datasetCode}`, {
            responseFormat: 'json',
            action: {
                key: 'referential.dataset.actions.find',
                params: {
                    code: datasetCode
                }
            }
        })
    },

    /**
     * Update dataset
     * @param {Dataset} dataset
     * @return {Promise<Dataset>}
     */
    update(dataset) {
        return call(`/referential-api/v1/datasets/${dataset.code}`, {
            responseFormat: 'json',
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dataset),
            action: {
                key: 'referential.dataset.actions.update',
                params: {
                    code: dataset.code
                }
            }
        })
    },

    /**
     * Delete dataset
     * @param {string} datasetCode
     * @return {Promise<boolean>}
     */
    delete(datasetCode) {
        return call(`/referential-api/v1/datasets/${datasetCode}`, {
            responseFormat: 'ignore',
            method: 'DELETE',
            action: {
                key: 'referential.dataset.actions.delete',
                params: {
                    code: datasetCode
                }
            }
        })
    },

    /**
     * Create dataset
     * @param {Dataset} dataset
     * @return {Promise<Dataset>}
     */
    create(dataset) {
        return call("/referential-api/v1/datasets", {
            responseFormat: 'json',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dataset),
            action: {
                key: 'referential.dataset.actions.create'
            }
        })
    },

    /**
     * Add new property to a dataset
     * @param {string} datasetCode
     * @param {DatasetProperty} property
     * @returns {Promise<Dataset>}
     */
    addProperty(datasetCode, property) {
        return call(`/referential-api/v1/datasets/${datasetCode}/properties`, {
            responseFormat: 'json',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(property),
            action: {
                key: 'referential.dataset.actions.add-property',
                params: {
                    code: datasetCode
                }
            }
        })
    },

    /**
     * Update a property
     * @param {string} datasetCode
     * @param {DatasetProperty} property
     * @returns {Promise<Dataset>}
     */
    updateProperty(datasetCode, property) {
        return call(`/referential-api/v1/datasets/${datasetCode}/properties/${property.code}`, {
            responseFormat: 'json',
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(property),
            action: {
                key: 'referential.dataset.actions.update-property',
                params: {
                    code: datasetCode,
                    propertyCode: property.code
                }
            }
        })
    },

    /**
     * Delete a property
     * @param {string} datasetCode
     * @param {string} propertyCode
     * @returns {Promise<Dataset>}
     */
    deleteProperty(datasetCode, propertyCode) {
        return call(`/referential-api/v1/datasets/${datasetCode}/properties/${propertyCode}`, {
            responseFormat: 'json',
            method: 'DELETE',
            action: {
                key: 'referential.dataset.actions.delete-property',
                params: {
                    code: datasetCode,
                    propertyCode: propertyCode
                }
            }
        })
    },

    /**
     * Save new properties order
     * @param {string} datasetCode
     * @param {Map} newOrder
     * @returns {Promise<Dataset>}
     */
    saveNewPropertiesOrder(datasetCode, newOrder) {
        return call(`/referential-api/v1/datasets/${datasetCode}/properties-order`, {
            responseFormat: 'json',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(Object.fromEntries(newOrder)),
            action: {
                key: 'referential.dataset.actions.save-properties-order',
                params: {
                    code: datasetCode
                }
            }
        })
    },

    /**
     * Search records
     * @param {string} datasetCode
     * @param {Object} filter
     * @returns {Promise<DatasetRecordPage>}
     */
    searchRecords(datasetCode, filter) {
        let query = Object.keys(filter)
            .filter(k => filter[k])
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(filter[k]))
            .join('&');
        let url = `/referential-api/v1/datasets/${datasetCode}/records`
        if (query) {
            url += "?" + query
        }
        return call(url, {
            responseFormat: 'json',
            action: {
                key: 'referential.dataset.actions.search-records',
                params: {
                    code: datasetCode
                }
            }
        })
    },

    /**
     * Search values
     * @param {string} datasetCode
     * @param {Object} filter
     * @returns {Promise<Map>}
     */
    propertiesValues(datasetCode, filter) {
        let query = Object.keys(filter)
            .filter(k => filter[k])
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(filter[k]))
            .join('&');
        let url = `/referential-api/v1/datasets/${datasetCode}/properties-values`
        if (query) {
            url += "?" + query
        }
        return call(url, {
            responseFormat: 'json',
            action: {
                key: 'referential.dataset.actions.search-values',
                params: {
                    code: datasetCode
                }
            }
        })
    },

    /**
     * Get property values
     * @param {string} datasetCode
     * @param {string} propertyCode
     * @returns {Promise<Array>}
     */
    propertyValues(datasetCode, propertyCode) {
        return call(`/referential-api/v1/datasets/${datasetCode}/properties/${propertyCode}/values`, {
            responseFormat: 'json',
            action: {
                key: 'referential.dataset.actions.search-property-values',
                params: {
                    code: datasetCode,
                    propertyCode: propertyCode
                }
            }
        })
    },

    /**
     * Add record to dataset
     * @param {string} datasetCode
     * @param {DatasetRecord} record
     * @returns {Promise<DatasetRecord>}
     */
    addRecord(datasetCode, record) {
        return call(`/referential-api/v1/datasets/${datasetCode}/records`, {
            responseFormat: 'json',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(record),
            action: {
                key: 'referential.dataset.actions.add-record',
                params: {
                    code: datasetCode,
                }
            }
        })
    },

    /**
     * Update record
     * @param {string} datasetCode
     * @param {DatasetRecord} record
     * @returns {Promise<DatasetRecord>}
     */
    updateRecord(datasetCode, record) {
        return call(`/referential-api/v1/datasets/${datasetCode}/records/${record.code}`, {
            responseFormat: 'json',
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(record),
            action: {
                key: 'referential.dataset.actions.update-record',
                params: {
                    code: datasetCode,
                    recordCode: record.code,
                }
            }
        })
    },

    /**
     * Delete a record
     * @param {string} datasetCode
     * @param {String} recordCode
     * @returns {Promise<* | boolean>}
     */
    deleteRecord(datasetCode, recordCode) {
        return call(`/referential-api/v1/datasets/${datasetCode}/records/${recordCode}`, {
            responseFormat: 'json',
            method: 'DELETE',
            action: {
                key: 'referential.dataset.actions.delete-record',
                params: {
                    code: datasetCode,
                    recordCode: recordCode
                }
            }
        })
    },

    /**
     * Export dataset records as CSV
     * @param {string} datasetCode
     * @returns {Promise<Blob>}
     */
    exportRecords(datasetCode) {
        return call(`/referential-api/v1/datasets/${datasetCode}/records/export`, {
            responseFormat: 'blob',
            method: 'POST',
            action: {
                key: 'referential.dataset.actions.export-records',
                params: {
                    code: datasetCode,
                }
            }
        })
    },

    /**
     * Import dataset records from CSV
     * @param {string} datasetCode
     * @param {File} file
     * @param {'MERGE'|'OVERRIDE'}mode
     * @returns {Promise<* | boolean>}
     */
    importRecords(datasetCode, file, mode) {
        let formData = new FormData();
        formData.append( 'file', file );
        return call(`/referential-api/v1/datasets/${datasetCode}/records/import?mode=${mode}`, {
            responseFormat: 'ignore',
            method: 'POST',
            headers: {
                'Accept': 'multipart/form-data'
            },
            body: formData,
            action: {
                key: 'referential.dataset.actions.import-records',
                params: {
                    code: datasetCode,
                }
            }
        })
    },

    /**
     * Dataset records mass deletions
     * @param {string} datasetCode
     * @param {Object} filter
     * @returns {Promise<*>}
     */
    massDeletions(datasetCode, filter) {
        let query = Object.keys(filter)
            .filter(k => filter[k])
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(filter[k]))
            .join('&');
        let url = `/referential-api/v1/datasets/${datasetCode}/records/mass-deletions`
        if (query) {
            url += "?" + query
        }
        return call(url, {
            responseFormat: 'json',
            method: 'POST',
            action: {
                key: 'referential.dataset.actions.records-mass-deletion',
                params: {
                    code: datasetCode,
                }
            }
        })
    }

}
