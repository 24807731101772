import {auth0} from "@/plugins/auth0";
import i18n from "@/plugins/i18n"
import app from "@/main"

const CATALOG_URL = import.meta.env.VITE_RULESHAKE_CATALOG_URL;
const REFERENTIAL_URL = import.meta.env.VITE_RULESHAKE_REFERENTIAL_URL;
const RUNNER_URL = import.meta.env.VITE_RULESHAKE_RUNNER_URL;

/**
 * @typedef ApiCallAction
 * @property {string} key
 * @property {Object=} params
 * @property {boolean=} silent
 */

/**
 * @typedef {Object} ApiCallOptions
 * @property {string=} [method]
 * @property {Record<String, String>=} [headers]
 * @property {Object=} [body]
 * @property {'json'|'text'|'blob'|'ignore'} responseFormat
 * @property {ApiCallAction} action
 */

/**
 * Do api call
 * @param {string} path
 * @param {ApiCallOptions} options
 */
export function call(path, options) {
    return auth0.getAccessTokenSilently()
        .then(token => {
            const headerAuth = {
                'Authorization': 'Bearer ' + token
            }
            const headers = {...headerAuth, ...options?.headers}
            const request = {
                method: options?.method ? options.method : 'GET',
                headers: headers,
                body: options?.body ? options.body : null,
            }
            const resolvedPath = path
                .replace(/^\/catalog-api/, CATALOG_URL)
                .replace(/^\/referential-api/, REFERENTIAL_URL)
                .replace(/^\/runner-api/, RUNNER_URL);
            return fetch(resolvedPath, request)
        })
        .then(response => {
            if (response.status >= 400) {
                return handleError(response, options.action)
            }
            if (options.responseFormat === 'json') {
                return response.json()
            }
            if (options.responseFormat === 'blob') {
                return response.blob()
            }
            if (options.responseFormat === 'text') {
                return response.text()
            }
            return true
        })
}

export function securedFetch(path, init) {
    return auth0.getAccessTokenSilently()
        .then(token => {
            const headerAuth = {
                'Authorization': 'Bearer ' + token
            }
            const headers = {...headerAuth, ...init?.headers}
            const request = init ? init : {}
            request.headers = headers
            const resolvedPath = path
                .replace(/^\/catalog-api/, CATALOG_URL)
                .replace(/^\/referential-api/, REFERENTIAL_URL)
                .replace(/^\/runner-api/, RUNNER_URL);
            return fetch(resolvedPath, request)
        });
}

export function handleJson(response, action) {
    if (response.status >= 400) {
        return handleError(response, action)
    }
    return response.json();
}

export function handleVoid(response, action) {
    if (response.status >= 400) {
        return handleError(response, action)
    }
    return true;
}

function handleError(response, action) {
    const {t} = i18n.global
    return response.text()
        .then(text => {
            let errorMsgKey = 'common.an-error-occurred'
            if (response.status === 401 || response.status === 403) {
                errorMsgKey = 'common.you-r-not-allowed'
            }
            const errorMsg = `${t(errorMsgKey)} ${t(action.key, action.params)}`
            app.$toast.error(errorMsg)
            throw errorMsg
        })
}