<template>
  <v-card-actions class="align-center">
    <Breadcrumb :reference="$route.params.collectionCode"/>
    <v-spacer/>
    <ButtonWithTooltip icon="mdi-play" color="primary" :edit="edit" @clicked="simulateCollection"
                       variant="contained-text" size="large" density="comfortable"
                       :tooltip-text="$t('catalog.collection.simulate')"
                       :label="$t('common.simulate')"/>
    <ButtonWithTooltip icon="mdi-download" color="action" :edit="edit" @clicked="exportCollection"
                       variant="contained-text" size="large" density="comfortable"
                       :tooltip-text="$t('common.export.title', {type: 'collection', label: collection.code})"
                       :label="$t('common.export.label')"/>
    <v-divider vertical class="mx-3"/>
    <ButtonWithTooltip icon="mdi-cube-send" color="action" :edit="edit" @clicked="release = true"
                       variant="contained-text" size="large" density="comfortable"
                       :tooltip-text="$t('catalog.collection.publish')"
                       :label="$t('common.publish')"/>
  </v-card-actions>
  <v-card class="mt-3" density="compact">
    <v-card-actions>
      <v-spacer/>
      <ComponentHistory :component="collection" :edit="edit"/>
      <v-divider vertical class="mx-3"></v-divider>
      <ButtonWithTooltip icon="mdi-pencil" color="primary" :edit="edit" @clicked="edit = !edit"
                         :tooltip-text="$t('catalog.collection.edit')"/>
      <ButtonWithTooltip icon="mdi-content-copy" color="action" :edit="edit" @clicked="duplicate = true"
                         :tooltip-text="$t('catalog.collection.duplicate.label')"/>
      <ButtonWithTooltip icon="mdi-delete" color="error" :edit="edit" @clicked="deleteCollection"
                         :tooltip-text="$t('catalog.collection.delete')"/>
    </v-card-actions>
    <v-card-text>

      <v-row align="center" justify="center">
        <v-col cols="5">
          <CodeTextField v-model="collection.code" :edit="false"/>
        </v-col>
        <v-col cols="6">
          <v-text-field v-model="collection.label" :label="$t('common.label')" :readonly="!edit"
                        :variant="edit ? 'outlined' : 'plain'" hide-details
                        :bg-color="edit ? 'white' : ''"/>
        </v-col>
        <v-col cols="11">
          <v-combobox v-model="tags" :items="[]" :label="$t('catalog.collection.tags')" :readonly="!edit" multiple chips :closable-chips="edit"
                      :variant="edit ? 'outlined' : 'plain'" hide-details
                      :style="edit ? 'background-color: white' : ''"></v-combobox>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn v-if="edit" color="error" @click="cancelUpdateCollection">{{ $t('common.cancel') }}</v-btn>
      <v-btn v-if="edit" color="primary" @click="updateCollection">{{ $t('common.save') }}</v-btn>
    </v-card-actions>
  </v-card>
  <div v-if="collection.code">
    <Publications :key="publicationsKey" type="COLLECTION" :code="collection.code"/>
    <SubVariables :reference="collection.code" :label="collection.label" v-model="variables" is-collection/>
  </div>
  <Loading :loading="loading" :text="$t('catalog.collection.loading-collection', {code: collection.code})" />
  <v-dialog v-model="openJson" persistent>
      <DisplayJson :code="collection.code" type="collection" :data="collectionExport" @closed="openJson = false" />
  </v-dialog>
  <v-dialog v-model="duplicate" persistent>
    <DuplicateCollection :to-duplicate="collection" @canceled="duplicate = false"
                         @duplicated="duplicateCollection"></DuplicateCollection>
  </v-dialog>
  <v-dialog v-model="release" persistent>
    <CreatePublication :component=collection @canceled="release = false" @saved="releaseCollection"/>
  </v-dialog>
</template>
<script>
import CollectionAPI from "@/api/catalog/CollectionAPI.js";
import VariableAPI from "@/api/catalog/VariableAPI";
import PublicationAPI from "@/api/catalog/PublicationAPI";
import Breadcrumb from "@/components/catalog/CatalogBreadcrumb.vue";
import SubVariables from "@/components/catalog/SubVariables.vue";
import ButtonWithTooltip from "@/components/common/ButtonWithTooltip.vue";
import ComponentHistory from "@/components/catalog/ComponentHistory.vue";
import CodeTextField from "@/components/common/CodeTextField.vue";
import DuplicateCollection from "@/components/catalog/DuplicateCollection.vue";
import CreatePublication from "@/components/catalog/CreatePublication.vue";
import Publications from "@/components/catalog/Publications.vue";
import Loading from "@/components/common/Loading.vue";
import DisplayJson from "@/components/common/DisplayJson.vue";

export default {
  name: "Collection",
  components: {
      DisplayJson,
    Loading,
    Publications,
    CreatePublication,
    DuplicateCollection, CodeTextField, ComponentHistory, ButtonWithTooltip, Breadcrumb, SubVariables
  },
  data: () => ({
    /** @type {Collection} */ collection: {},
    /** @type {CollectionExport} */ collectionExport: {},
    /** @type {Array<Variable>} */ variables: [],
    /** @type {Array<string>} */ tags: [],
    edit: false,
    openJson: false,
    duplicate: false,
    release: false,
    publicationsKey: 0,
    loading: true
  }),
  mounted() {
    CollectionAPI.find(this.$route.params.collectionCode)
        .then(result => {
          this.collection = result;
          this.tags = this.collection.tags ? this.collection.tags : [];
          VariableAPI.listCollectionVariables(this.$route.params.collectionCode)
              .then(variablesList => this.variables = variablesList)
              .finally(() => this.loading = false);
          this.$store.dispatch('setCurrentCollection', this.collection.code)
        })
        .catch(() => this.$router.push({name: 'catalog'}))
  },
  methods: {
    deleteCollection() {
      this.$root.$confirm(this.$t('catalog.collection.delete-msg', {label: this.collection.label}), this.$t('catalog.collection.confirm-delete-msg', {label: this.collection.label}), this.collection.code)
          .then(confirm => {
            if (confirm) {
              CollectionAPI.delete(this.collection.code)
                  .then(deleteOK => {
                    if (deleteOK) {
                      this.$toast.warning(this.$t('catalog.collection.deleted', {label: this.collection.label}))
                      this.$router.push({name: 'catalog'});
                    } else {
                      this.$toast.error(this.$t('catalog.collection.error-on-delete', {label: this.collection.label}))
                    }
                  })
            }
          })
    },
    cancelUpdateCollection() {
      this.edit = !this.edit
      this.tags = this.collection.tags ? this.collection.tags : [];
    },
    updateCollection() {
      this.collection.tags = this.tags;
      CollectionAPI.update(this.collection)
          .then(() => this.$toast.success(this.$t('catalog.collection.saved', {label: this.collection.label})))
          .finally(() => this.edit = false)
    },
    exportCollection() {
      CollectionAPI.exportCollection(this.collection.code)
          .then(response => this.collectionExport = response)
          .finally(() => this.openJson = !this.openJson)
    },
    releaseCollection(publication) {
      PublicationAPI.publishCollection(this.collection.code, publication)
          .then(publication => {
            this.$toast.success(this.$t('catalog.collection.released', {label: this.collection.label}))
          })
          .finally(() => {
            this.release = false
            this.publicationsKey += 1
          })
    },
    simulateCollection() {
      this.$router.push({name: 'simulation', params: {collectionCode: this.collection.code}})
    },
    duplicateCollection(duplicateInfos) {
      CollectionAPI.duplicateCollection(this.collection.code, duplicateInfos)
          .then(created => {
            this.$toast.success(this.$t('catalog.collection.duplicated', {
              label: created.label,
              origin: this.collection.code
            }))
            this.$router.push({name: 'collection', params: {collectionCode: created.code}})
          })
    }
  }
}
</script>