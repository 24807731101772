<template>
  <v-expansion-panels class="mt-4" v-model="panel">
    <v-expansion-panel value="records">
      <v-expansion-panel-title>
        <span class="text-h6 font-weight-medium">
          {{ $tc('referential.classifier.label', 2) }} ({{ classifiers && classifiers.length > 0 ? classifiers.length : 0 }})
        </span>
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <Components :components="classifiers" :enable-tags="true" :component-label="$tc('referential.classifier.label', 1)"
                    :create-component-label="$t('referential.classifier.create')" :allow-creation="true"
                    @create="createClassifier = true" @navigate="navigateToClassifier"/>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
  <v-dialog v-model="createClassifier" persistent>
    <CreateClassifier :dataset="dataset" @canceled="createClassifier = false" @saved="doCreateClassifier"></CreateClassifier>
  </v-dialog>
</template>
<script>
import Classifiers from "@/components/referential/Classifiers.vue";
import ClassifierAPI from "@/api/referential/ClassifierAPI";
import CreateClassifier from "@/components/referential/CreateClassifier.vue";
import Components from "@/components/common/Components.vue";

export default {
  name: "DatasetClassifiers",
  components: {CreateClassifier, Classifiers, Components},
  props: {
    dataset: {}
  },
  data: () => ({
    panel: [],
    classifiers: [],
    createClassifier: false
  }),
  mounted() {
    ClassifierAPI.listByDataset(this.dataset.code)
        .then(result => {
          this.classifiers = result
          this.classifiers.forEach(classifier => classifier.tags = [classifier.datasetCode])
        })
  },
  methods: {
    doCreateClassifier(classifierToCreate) {
      this.createDataset = false;
      ClassifierAPI.create(classifierToCreate)
          .then(created => {
            this.$toast.success(this.$t('referential.classifier.created', {code: created.code}))
            this.navigateToClassifier(created.code);
          })
    },
    navigateToClassifier(classifierCode) {
      this.$router.push({name: 'classifier', params: {classifierCode: classifierCode}})
    }
  }
}
</script>