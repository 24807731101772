<template>
  <v-tooltip v-model="tooltip" location="bottom">
    <template v-slot:activator="{ props }">
      <v-btn v-bind="props" icon="mdi-code-tags" :color="edit ? '' : 'action'" :disabled="edit" @click="showRules"/>
    </template>
    <span>Show {{ variable.label }}'s rules</span>
  </v-tooltip>
  <v-dialog v-model="show" persistent>
    <v-card width="1025px" class="mx-auto">
      <v-toolbar dark color="primary" dense flat class="pa-0 px-4">
        <v-toolbar-title class="white--text">
          <span><span class="font-weight-medium">{{ variable.label }}</span> rules</span>
        </v-toolbar-title>
        <v-spacer/>
        <v-btn icon="mdi-close" @click="show=false"/>
      </v-toolbar>

      <v-expansion-panels v-if="rules.length" class="py-4 px-4" v-model="panels" multiple>
        <v-expansion-panel v-for="rule in rules" :value="rule.name">
          <v-expansion-panel-title>
            <span class="text-h6 font-weight-medium">{{ rule.name }}</span>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <v-row align="center" justify="center">
              <v-col>
                <v-ace-editor v-model:value="rule.generatedExpression" lang="javascript" style="min-height: 75px" readonly :print-margin="false" :options="editorOptions"/>
              </v-col>
            </v-row>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
      <span v-else class="pa-6"><strong>{{ variable.label }}</strong> has only static attributes/properties and no rules</span>
    </v-card>
  </v-dialog>
</template>
<script>
import VariableAPI from "@/api/catalog/VariableAPI";
import {VAceEditor} from 'vue3-ace-editor';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-chrome';

export default {
  name: 'VariableRules',
  components: {VAceEditor},
  props: {
    variable: Object,
    edit: Boolean
  },
  data: () => ({
    tooltip: false,
    show: false,
    panels: [],
    rules: [],
    editorOptions: {
      highlightActiveLine: false,
      highlightGutterLine: false,
      minLines: 4,
      maxLines: 20
    }
  }),
  methods: {
    showRules() {
      VariableAPI.getVariableRules(this.variable.reference.parent + "/" + this.variable.reference.code)
          .then(result => {
            this.show = true;
            this.rules = result;
          })
    }
  }
}
</script>