<template>
    <Loading :loading="loading" :text="$t('referential.classifier.loading-classifiers')"/>
    <Components :components="classifiers" :enable-tags="true" :component-label="$tc('referential.classifier.label', 1)"
                :create-component-label="$t('referential.classifier.create')" :allow-creation="true"
                @create="createClassifier = true" @navigate="navigateToClassifier"/>
    <v-dialog v-model="createClassifier" persistent>
        <CreateClassifier @canceled="createClassifier = false" @saved="doCreateClassifier" @imported="doCreateClassifier" />
    </v-dialog>
</template>
<script>
import ClassifierAPI from "@/api/referential/ClassifierAPI";
import CreateClassifier from "@/components/referential/CreateClassifier.vue";
import Components from "@/components/common/Components.vue";
import Loading from "@/components/common/Loading.vue";

export default {
    name: "Classifiers",
    components: {Loading, Components, CreateClassifier},
    data: () => ({
        classifiers: [],
        createClassifier: false,
        loading: true
    }),
    mounted() {
        ClassifierAPI.list()
            .then(result => {
                this.classifiers = result
                this.classifiers.forEach(classifier => classifier.tags = [classifier.datasetCode])
            })
            .finally(() => this.loading = false);
    },
    methods: {
        doCreateClassifier(classifierToCreate) {
            this.createDataset = false;
            ClassifierAPI.create(classifierToCreate)
                .then(created => {
                    this.$toast.success(this.$t('referential.classifier.created', {code: created.code}))
                    this.navigateToClassifier(created.code);
                })
        },
        navigateToClassifier(classifierCode) {
            this.$router.push({name: 'classifier', params: {classifierCode: classifierCode}})
        }
    }
}
</script>