<template>
  <v-text-field v-model="model" :label="label" :readonly="!edit" :variant="edit ? 'outlined' : 'plain'" hide-details
                :bg-color="edit ? 'white' : ''" v-on:keypress="checkCode" style="font-family: Consolas, Menlo, Courier, monospace"/>
</template>
<script>
export default {
  name: "CodeTextField",
  props: {
    label: {
      default: 'Code'
    },
    modelValue: null,
    edit: false,
    allowStartByNumber: false
  },
  computed:{
    model: {
      get() { return this.modelValue },
      set(newValue) { this.$emit('update:modelValue', newValue.toUpperCase()) }
    }
  },
  methods: {
    checkCode(e) {
      let regex = /^\w+$/
      if (!this.modelValue && !this.allowStartByNumber) {
        regex = /^[A-Za-z]+$/
      }
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (regex.test(char)) return true; // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    }
  }
}
</script>