<template>
  <v-expansion-panels class="mt-4" v-model="panel">
    <v-expansion-panel value="properties">
      <v-expansion-panel-title>
        <span class="text-h6 font-weight-medium">
          {{ $t('catalog.variable.properties.title') }}
          ({{ variable.properties && variable.properties.length > 0 ? variable.properties.length : 0 }})
        </span>
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <v-card-text>
          <v-card-title>
            <v-row class="mb-4 mt-2">
              <v-spacer/>
              <v-btn color="primary" @click="openProperty=true">
                <v-icon class="mr-2">mdi-plus-thick</v-icon>
                <span>{{ $t('catalog.variable.properties.add') }}</span>
              </v-btn>
            </v-row>
          </v-card-title>
          <div v-if="variable.properties && variable.properties.length > 0">
            <v-row class="border my-1 pa-2 bg-primary" dense>
              <v-col cols="4">
                <span class="text-subtitle-1 font-weight-bold ml-6">{{ $t('common.code') }}</span>
              </v-col>
              <v-col cols="7">
                <span class="text-subtitle-1 font-weight-bold">{{ $t('common.label') }}</span>
              </v-col>
            </v-row>
            <div v-for="element in variable.properties">
              <v-row align="center" style="cursor: pointer" class="border pa-2 bg-grey-lighten-5" dense>
                <v-col cols="4">
                  <strong class="ml-2" style="font-family: Consolas, Menlo, Courier, monospace; font-size: medium">{{ element.name }}</strong>
                </v-col>
                <v-col cols="7">
                  <span class="text-subtitle-1 font-weight-regular font-italic">{{ element.label }}</span>
                </v-col>
                <v-col class="d-flex flex-row">
                  <v-btn icon="mdi-pencil" size="small" color="primary" variant="text"
                         @click="editProperty(element)"></v-btn>
                  <v-btn icon="mdi-delete" size="small" color="error" variant="text" class="ml-2"
                         @click="deleteProperty(element)"></v-btn>
                </v-col>
              </v-row>
            </div>
          </div>
          <v-alert v-else type="info" prominent variant="outlined">
            <span class="text-subtitle-1 mb-6 mt-3"
                  v-html="$t('catalog.variable.properties.has-no-properties-alert', {label: variable.label})"/>
          </v-alert>
        </v-card-text>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>

  <v-dialog v-model="openProperty" persistent>
    <Property :parent="variable.reference.parent + '/' + variable.reference.code" :property="propertyToEdit"
              @canceled="cancelPropertyEdition" @saved="saveProperty" @deleted="deleteProperty"></Property>
  </v-dialog>
</template>
<script>
import ValueOrFormula from "@/components/catalog/ValueOrFormula.vue";
import Property from "@/components/catalog/Property.vue";
import VariableAPI from "@/api/catalog/VariableAPI";

export default {
  name: "Properties",
  components: {Property, ValueOrFormula},
  props: {
    /** @type {Variable} */ variable: {}
  },
  data: () => ({
    edit: false,
    openProperty: false,
    panel: [],
    propertyToEdit: {
      creation: true,
      valueOrFormula: {}
    }
  }),
  methods: {
    cancelPropertyEdition() {
      this.openProperty = false;
      this.propertyToEdit = {
        creation: true,
        valueOrFormula: {}
      };
    },
    deleteProperty(property) {
      this.$root.$confirm(this.$t('catalog.variable.properties.delete-msg', {name: property.name}), this.$t('catalog.variable.properties.confirm-delete-msg', {name: property.name}))
          .then(confirm => {
            if (confirm) {
              this.openProperty = false;
              VariableAPI.deleteProperty(this.variable.reference.parent + '/' + this.variable.reference.code, property)
                  .then(result => this.variable.properties = this.variable.properties.filter(p => p.name !== property.name));
            }
          });

    },
    saveProperty(property) {
      this.openProperty = false;
      const varRef = this.variable.reference.parent + '/' + this.variable.reference.code;
      if (property.creation) {
        VariableAPI.addProperty(varRef, property)
            .then(saved => this.variable.properties = saved.properties);
      } else {
        VariableAPI.updateProperty(varRef, property)
            .then(saved => this.variable.properties = saved.properties);
      }
    },
    editProperty(property) {
      this.propertyToEdit = property;
      this.openProperty = true;
    }
  }
}
</script>