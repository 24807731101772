<template>
  <v-card-actions class="mb-3">
    <v-row align="center">
      <v-col cols="8">
        <Breadcrumb :reference="$route.params.collectionCode + '/Simulation'"/>
      </v-col>
      <v-col class="d-flex justify-space-between align-center">
        <v-select v-model="selectedCase" :items="cases" clearable variant="outlined" item-title="code" dense hide-details bg-color="white"
                  :label="$t('catalog.simulation.case.load')" class="mr-2" @update:modelValue="loadCase" return-object></v-select>
        <v-btn icon="mdi-cog" @click="editCases = !editCases"></v-btn>
      </v-col>
    </v-row>
  </v-card-actions>
  <SimulationForm v-if="collection.code" :definitions="collection.variables" :collection-code="collection.code" @cleared="clearInputs"
                  @saved="onCaseSaved"/>
  <v-dialog v-model="editCases" persistent>
    <v-card width="720px" class="mx-auto">
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title class="white--text">{{ $t('catalog.simulation.case.edit') }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-table>
          <thead>
          <tr>
            <th id="code" class="text-left">{{ $t('common.code') }}</th>
            <th id="actions"/>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in cases" :key="item.code">
            <td>{{ item.code }}</td>
            <td class="d-flex flex-row align-center justify-end">
              <v-btn icon="mdi-delete" size="small" color="error" variant="text" class="ml-2" @click="deleteCase(item)"></v-btn>
            </td>
          </tr>
          </tbody>
        </v-table>
      </v-card-text>
      <v-card-actions>
        <v-row class="mr-3">
          <v-spacer></v-spacer>
          <v-btn @click="editCases = !editCases" color="action">{{ $t('common.ok') }}</v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <Loading :loading="loading" :text="$t('catalog.simulation.loading-form')" />
</template>
<script>
import CollectionAPI from "@/api/catalog/CollectionAPI";
import SimulationAPI from "@/api/catalog/SimulationAPI";
import SimulationForm from "@/components/catalog/SimulationForm.vue";
import Breadcrumb from "@/components/catalog/CatalogBreadcrumb.vue";
import Loading from "@/components/common/Loading.vue";

export default {
  name: "Simulation",
  components: {Loading, SimulationForm, Breadcrumb},
  data: () => ({
    /** @type {CollectionExport} */ collection: {},
    cases: [],
    selectedCase: null,
    editCases: false,
    loading: false
  }),
  mounted() {
    this.loading = true
    CollectionAPI.exportCollection(this.$route.params.collectionCode)
        .then(result => {
          this.$store.dispatch('setCurrentDefinitions', result.variables)
          this.collection = result;
          SimulationAPI.listCases(this.collection.code)
              .then(cases => this.cases = cases)
        })
        .finally(() => this.loading = false)
  },
  unmounted() {
    this.clearInputs();
  },
  methods: {
    deleteCase(simulationCase) {
      SimulationAPI.deleteCase(simulationCase)
          .then(result => {
            this.cases = this.cases.filter(c => c.code !== simulationCase.code)
            this.$toast.warning(this.$t('catalog.simulation.case.deleted', {code: simulationCase.code}))
          })
    },
    async loadCase(selectedCase) {
      if (selectedCase) {
        const caseData = await SimulationAPI.getCase(this.collection.code, selectedCase.code)
        this.$store.dispatch('setSelectedSimulationCase', caseData)
      } else {
        this.clearInputs()
      }
    },
    clearInputs() {
      this.selectedCase = null
      this.$store.dispatch('setSelectedSimulationCase', null)
    },
    onCaseSaved(savedCase) {
      if (this.cases.findIndex(c => c.code === savedCase) === -1) {
        this.cases.push({
          code: savedCase
        })
        this.selectedCase = savedCase
      }
    }
  }
}
</script>