<template>
  <v-hover>
    <template v-slot:default="{ isHovering, props }">
      <v-card v-bind="props" :color="isHovering ? 'green-lighten-4' : 'secondary'">
        <v-card-text>
          <v-row align="center">
            <v-col class="d-flex justify-space-between">
              <span class="font-weight-regular text-h6 ml-4">
                <strong style="font-family: Consolas, Menlo, Courier, monospace; font-size: large">
                  {{ component.code }}
                </strong>
                / {{ component.label }}
              </span>
              <div v-if="component.tags">
                <v-chip v-for="tag in component.tags" color="red" class="mx-1">
                  <span class="font-weight-medium">{{ tag }}</span>
                </v-chip>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </v-hover>
</template>
<script>
export default {
  name: "ComponentItem",
  props: {
    component: {}
  }
}
</script>