<template>
  <div v-if="modelValue" class="my-4">
    <v-row v-if="showFormula">
      <v-col>
        <span class="text-subtitle-1 font-weight-medium d-flex align-center ml-2">{{ label }}</span>
        <v-switch v-if="type === 'boolean'" v-model="modelValue.value" hide-details color="primary" inset
                  :disabled="!edit" :label="`Default: ${modelValue.value? modelValue.value.toString() : 'false'}`">
        </v-switch>
        <v-text-field v-else v-model="modelValue.value" :type="type" :label="$t('catalog.variable.default')"
                      hide-details :variant="edit ? 'outlined' : 'plain'" :readonly="!edit" density="compact"
                      class="mt-2" :bg-color="edit ? 'white' : ''"></v-text-field>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col cols="9">
        <div class="d-flex justify-space-between align-center">
          <span class="text-overline">
            {{ $t('catalog.variable.attribute-formula', {attribute: label}) }}
          </span>
          <v-btn v-if="edit" color="error" @click="deleteFormula" density="compact" size="small" variant="text">
            {{ $t('catalog.variable.delete-formula') }}
          </v-btn>
        </div>
        <v-ace-editor v-if="edit" v-model:value="modelValue.formula" @init="editorInit" style="min-height: 75px"
                      :readonly="!edit"
                      :print-margin="false" :options="editorOptions"/>
        <pre v-else>{{ modelValue.formula }}</pre>
      </v-col>
    </v-row>
    <v-row v-else align="center">
      <v-col cols="3">
        <span class="text-subtitle-1 font-weight-medium d-flex align-center ml-2">{{ label }}</span>
      </v-col>
      <v-col>
        <v-switch v-if="type === 'boolean'" v-model="modelValue.value" hide-details color="primary" inset
                  :disabled="!edit" :label="`${modelValue.value? $t('common.yes') : $t('common.no')}`"></v-switch>
        <v-text-field v-else v-model="modelValue.value" :type="type" hide-details :variant="edit ? 'outlined' : 'plain'"
                      :readonly="!edit" density="compact" :bg-color="edit ? 'white' : ''"></v-text-field>
      </v-col>
      <v-col cols="2" v-if="edit" class="d-flex align-center justify-center">
        <v-btn color="primary" @click="showFormula = !showFormula" variant="text">
          {{ $t('catalog.variable.add-formula') }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import {VAceEditor} from 'vue3-ace-editor';
import 'ace-builds/src-noconflict/theme-chrome';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/ace';
import {getParents} from "@/util"

export default {
  name: "ValueOrFormula",
  components: {VAceEditor},
  props: {
    label: String,
    type: String,
    edit: Boolean,
    /** @type {ValueOrFormula} */ modelValue: {
      value: null,
      formula: ''
    }
  },
  data: () => ({
    showFormula: false,
    editorOptions: {
      highlightActiveLine: false,
      highlightGutterLine: false,
      minLines: 4,
      maxLines: 20,
      enableBasicAutocompletion: true,
      enableLiveAutocompletion: true,
      enableSnippets: true,
      fontSize: '10pt'
    }
  }),
  mounted() {
    this.showFormula = this.modelValue.formula
  },
  computed: {
    autocompletionGraph() {
      return this.$store.getters.autocompletionGraph;
    }
  },
  methods: {
    updateValue(event) {
      this.$emit('update:modelValue', event.target.value);
    },
    deleteFormula() {
      this.modelValue.formula = undefined
      this.showFormula = false
    },
    editorInit: function (editor) {
      const self = this;
      const staticWordCompleter = {
        getCompletions: function (editor, session, pos, prefix, callback) {
          let tokens = self._tokenize(session.getLine(pos.row), pos.column, prefix)
          if (tokens && tokens[tokens.length - 1] === 'value') {
            return;
          }
          if (tokens && tokens.length >= 2 && tokens[tokens.length - 2] === 'properties') {
            return;
          }

          let wordList = [];
          if (tokens && tokens[tokens.length - 1] === 'properties') {
            tokens.pop()
            let autocomplete = self._getAutocompleteSubGraph(tokens)
            wordList = autocomplete.properties.map(prop => self._toWord(prop.name, prop.type, prop.name, prop.description))
          } else {
            let autocomplete = self._getAutocompleteSubGraph(tokens)
            if (autocomplete && autocomplete.children) {
              wordList = Object.values(autocomplete.children)
            } else if (autocomplete && !autocomplete.multiple) {
              wordList.push(self._toWord('value', 'variable value'))
              if (autocomplete.properties) {
                wordList.push(self._toWord('properties', 'variable properties'))
              }
            }
          }

          callback(
              null,
              wordList.map(word => ({
                caption: word.caption ? word.caption : word.name,
                value: word.name,
                meta: (word.multiple ? 'list of ' : '') + word.type.toLowerCase(),
                docText: word.description,
                snippet: self._toSnippet(word)
              }))
          );
        },
      };

      editor.completers = [staticWordCompleter];
      editor.commands.on("afterExec", function (e) {
        if (e.command.name === "insertstring" && e.args === '.') {
          editor.execCommand("startAutocomplete");
        }
      });
    },
    parents() {
      return getParents(this.$store.state.currentVariable)
    },
    _tokenize(line, column, prefix) {
      const reversed = Array.from(line)
          .reverse()
          .join('');

      const newPos = line.length - (column - prefix.length);
      const sub = reversed.substring(newPos);
      if (!sub.startsWith('.')) {
        return [];
      }

      let parents = "";
      for (const c of Array.from(sub)) {
        if (/^[a-zA-Z\d_.\[\]]+$/.test(c)) {
          parents = parents.concat(c);
        } else {
          break;
        }
      }
      return Array.from(parents)
          .reverse()
          .join('')
          .split('.')
          .filter(e => e.length > 0)
          .map(e => {
            if (e.includes('[')) {
              return e.substring(0, e.indexOf('['))
            }
            return e
          });
    },
    _getAutocompleteSubGraph(tokens) {
      const resolvedTokens = this._resolveParents(tokens)
      let autocomplete = {
        children: this.autocompletionGraph
      }
      let hasParent = false;
      if (!tokens.length && resolvedTokens.parents.length) {
        hasParent = true;
      }
      resolvedTokens.tokens.forEach((parent, index) => {
        if (autocomplete && autocomplete.children) {
          autocomplete = autocomplete.children[parent]
        } else {
          autocomplete = undefined
          hasParent = false
        }
        if (index > 0 && tokens[tokens.length - 1] === '_parent') {
          hasParent = true
        }
      })
      if (hasParent && autocomplete && autocomplete.children) {
        let caption = null;
        caption = "_parent (" + resolvedTokens.parents[resolvedTokens.parents.length - resolvedTokens.count - 1] + ")"
        autocomplete.children._parent = this._toWord('_parent', 'COMPOSITE', caption)
      } else if (autocomplete && autocomplete.children) {
        delete autocomplete.children._parent
      }
      return autocomplete
    },
    _toWord(name, type, caption = undefined, description = undefined) {
      return {
        name: name,
        caption: caption,
        type: type,
        description: description
      }
    },
    _toSnippet(autocomplete) {
      if (autocomplete.scope === 'METHOD') {
        let snippet = autocomplete.name
        if (autocomplete.parameters) {
          snippet += '(' + Object.values(autocomplete.parameters).map((parameter, index) => '${' + (index + 1) + ':' + parameter.name + ':' + parameter.type + '}').join(', ') + ')'
        } else {
          snippet += '()'
        }
        return snippet
      }
      return undefined
    },
    _resolveParents(tokens) {
      const refToParents = tokens ? tokens.filter(t => t === '_parent').length : 0
      const parents = this.parents();
      if (refToParents) {
        const parentsToAppend = parents.slice(0, parents.length - refToParents + 1);
        const tokensWithoutParent = tokens.filter(t => t !== '_parent')
        return {
          count: refToParents,
          tokens: parentsToAppend.concat(tokensWithoutParent),
          parents: parents
        }
      }
      return {
        count: 0,
        tokens: tokens || [],
        parents: parents
      }
    }
  }
}
</script>