<template>
    <Loading :loading="loading" :text="$t('referential.dataset.loading-datasets')"/>
    <Components :components="datasets" :component-label="$tc('referential.dataset.label', 1)"
                :create-component-label="$t('referential.dataset.create')" :allow-creation="true"
                @create="createDataset = true" @navigate="navigateToDataset"/>
    <v-dialog v-model="createDataset" persistent>
        <CreateDataset @canceled="createDataset = false" @saved="doCreateDataset" @imported="doCreateDataset" />
    </v-dialog>
</template>
<script>
import CreateDataset from "@/components/referential/CreateDataset.vue";
import DatasetAPI from "@/api/referential/DatasetAPI";
import Components from "@/components/common/Components.vue";
import Loading from "@/components/common/Loading.vue";

export default {
    name: 'Datasets',
    components: {Loading, Components, CreateDataset},
    data: () => ({
        /** @type {Array<Dataset>} */ datasets: [],
        createDataset: false,
        loading: true
    }),
    mounted() {
        DatasetAPI.list()
            .then(result => this.datasets = result)
            .finally(() => this.loading = false);
    },
    methods: {
        doCreateDataset(datasetToCreate) {
            this.createDataset = false;
            DatasetAPI.create(datasetToCreate)
                .then(created => {
                    this.$toast.success(this.$t('referential.dataset.created', {code: created.code}))
                    this.navigateToDataset(created.code);
                })
        },
        navigateToDataset(datasetCode) {
            this.$router.push({name: 'dataset', params: {datasetCode: datasetCode}})
        }
    }
}
</script>