/**
 * Variable Reference definition
 * @typedef {Object} VariableRef
 * @property {string} parent
 * @property {string} code
 */

/**
 * Value Or Formula definition
 * @typedef {Object} ValueOrFormula
 * @property {Object} value
 * @property String formula
 */

/**
 * Property definition
 * @typedef {Object} Property
 * @property {string} name
 * @property {string} label
 * @property {ValueOrFormula} valueOrFormula
 */

/**
 * Variable definition
 * @typedef {Object} Variable
 * @property {'STRING'|'COMPOSITE'|'BOOLEAN'|'NUMBER'|'DATE'} type
 * @property {VariableRef} reference
 * @property {string} label
 * @property {'INGRESS'|'COMPUTED'} nature
 * @property {boolean} multiple
 * @property {ValueOrFormula} mandatory
 * @property {ValueOrFormula} minOccurrence
 * @property {ValueOrFormula} maxOccurrence
 * @property {Set<Property>} properties
 *
 * Common to boolean, string, number and date
 * @property {Object} value
 *
 * Common to number
 * @property {Object} minValue
 * @property {Object} maxValue
 *
 * Common to date
 * @property {Date} minDate
 * @property {Date} maxDate
 *
 * Common to string and number
 * @property {Array<Object>} validValues
 *
 * Specific to string
 * @property {ValueOrFormula} minLength
 * @property {ValueOrFormula} maxLength
 * @property {string} valuePattern
 *
 * Specific to number
 * @property {ValueOrFormula} pace
 * @property {number} scale
 * @property {'UP'|'DOWN'|'CEILING'|'FLOOR'|'HALF_UP'|'HALF_DOWN'|'HALF_EVEN'|'UNNECESSARY'} roundingMode
 *
 * Specific to composite
 * @property {Map} loopOver
 */

import {call, handleJson, handleVoid, securedFetch} from "@/api/ApiUtil";

export default {

    /**
     * List collection's variables
     * @param {string} collectionCode
     * @return {Promise<Array<Variable>>}
     */
    listCollectionVariables(collectionCode) {
        return call(`/catalog-api/v1/collections/${collectionCode}/variables`, {
            responseFormat: 'json',
            action: {
                key: 'catalog.variable.actions.list-collection-variables',
                params: {
                    code: collectionCode
                }
            }
        })
    },

    /**
     * Find a variable
     * @param {string} variableReference
     * @return {Promise<Variable>}
     */
    find(variableReference) {
        return call(`/catalog-api/v1/variables/${encodeURIComponent(variableReference)}`, {
            responseFormat: 'json',
            action: {
                key: 'catalog.variable.actions.load-variable',
                params: {
                    code: variableReference
                }
            }
        })
    },

    /**
     * List composite's sub variables
     * @param {string} variableReference
     * @return {Promise<Array<Variable>>}
     */
    listCompositeVariables(variableReference) {
        return call(`/catalog-api/v1/variables/${encodeURIComponent(variableReference)}/sub-variables`, {
            responseFormat: 'json',
            action: {
                key: 'catalog.variable.actions.list-composite-sub-variables',
                params: {
                    code: variableReference
                }
            }
        })
    },

    /**
     * Create variable under a collection
     * @param {string} collectionCode
     * @param {Variable} variable
     */
    addVariableToCollection(collectionCode, variable) {
        return call(`/catalog-api/v1/collections/${collectionCode}/variables`, {
            responseFormat: 'json',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(variable),
            action: {
                key: 'catalog.variable.actions.add-variable-to-collection',
                params: {
                    code: collectionCode
                }
            }
        })
    },

    /**
     * Create variable under a composite variable
     * @param {string} compositeRef
     * @param {Variable} variable
     * @return {Promise<Variable>}
     */
    addVariableToComposite(compositeRef, variable) {
        return call(`/catalog-api/v1/variables/${encodeURIComponent(compositeRef)}/sub-variables`, {
            responseFormat: 'json',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(variable),
            action: {
                key: 'catalog.variable.actions.add-variable-to-composite',
                params: {
                    code: compositeRef
                }
            }
        })
    },

    /**
     * Delete variable
     * @param {string} variableReference
     * @return {Promise<boolean>}
     */
    deleteVariable(variableReference) {
        return call(`/catalog-api/v1/variables/${encodeURIComponent(variableReference)}`, {
            responseFormat: 'ignore',
            method: 'DELETE',
            action: {
                key: 'catalog.variable.actions.delete-variable',
                params: {
                    code: variableReference
                }
            }
        })
    },

    /**
     * Update variable
     * @param {Variable} variable
     * @return {Promise<Variable>}
     */
    updateVariable(variable) {
        let variableReference = `${variable.reference.parent}/${variable.reference.code}`;
        return call(`/catalog-api/v1/variables/${encodeURIComponent(variableReference)}`, {
            responseFormat: 'json',
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(variable),
            action: {
                key: 'catalog.variable.actions.update-variable',
                params: {
                    code: variableReference
                }
            }
        })
    },

    /**
     * Add property to variable
     * @param {string} variableReference
     * @param {Property} property
     * @return {Promise<Variable>}
     */
    addProperty(variableReference, property) {
        return call(`/catalog-api/v1/variables/${encodeURIComponent(variableReference)}/properties`, {
            responseFormat: 'json',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(property),
            action: {
                key: 'catalog.variable.actions.add-property',
                params: {
                    code: variableReference
                }
            }
        })
    },

    /**
     * Update property to variable
     * @param {string} variableReference
     * @param {Property} property
     * @return {Promise<Variable>}
     */
    updateProperty(variableReference, property) {
        return call(`/catalog-api/v1/variables/${encodeURIComponent(variableReference)}/properties/${property.name}`, {
            responseFormat: 'json',
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(property),
            action: {
                key: 'catalog.variable.actions.update-property',
                params: {
                    code: property.name
                }
            }
        })
    },

    /**
     * Delete variable
     * @param {string} variableReference
     * @param {Property} property
     * @return {Promise<boolean>}
     */
    deleteProperty(variableReference, property) {
        return call(`/catalog-api/v1/variables/${encodeURIComponent(variableReference)}/properties/${property.name}`, {
            responseFormat: 'ignore',
            method: 'DELETE',
            action: {
                key: 'catalog.variable.actions.delete-property',
                params: {
                    code: property.name
                }
            }
        })
    },

    /**
     * Get variable rules
     * @param {string} variableReference
     * @returns {Promise<*>}
     */
    getVariableRules(variableReference) {
        return call(`/catalog-api/v1/variables/${encodeURIComponent(variableReference)}/rules`, {
            responseFormat: 'json',
            action: {
                key: 'catalog.variable.actions.get-variable-rules',
                params: {
                    code: variableReference
                }
            }
        })
    },

    /**
     * Get available looping variables references
     * @param {string} variableReference
     * @returns {Promise<Array<string>>}
     */
    getAvailableLoopingVariablesReferences(variableReference) {
        const collectionCode = variableReference.substring(0, variableReference.indexOf('/'))
        return call(`/catalog-api/v1/collections/${collectionCode}/variables-names?multiple=true`, {
            responseFormat: 'json',
            action: {
                key: 'catalog.variable.actions.list-available-looping-variables',
                params: {
                    code: variableReference
                }
            }
        })
            .then(result => result.filter(e => !e.startsWith(variableReference)).map(e => e.substring(e.indexOf('/') + 1)))
    },

    /**
     * List composite variables
     * @param {string} collectionCode
     * @returns {Promise<Array<string>>}
     */
    getCompositesVariable(collectionCode) {
        return call(`/catalog-api/v1/collections/${collectionCode}/variables-names?type=COMPOSITE`, {
            responseFormat: 'json',
            action: {
                key: 'catalog.variable.actions.list-composite-variables',
                params: {
                    code: collectionCode
                }
            }
        })
    },

    /**
     * Save new sub variables order
     * @param {string} variableReference
     * @param {Map} newOrder
     * @return {Promise<boolean>}
     */
    saveNewSubVariablesOrder(variableReference, newOrder) {
        return call(`/catalog-api/v1/variables/${encodeURIComponent(variableReference)}/sub-variables-order`, {
            responseFormat: 'ignore',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(Object.fromEntries(newOrder)),
            action: {
                key: 'catalog.variable.actions.save-variables-new-order',
                params: {
                    code: variableReference
                }
            }
        })
    },

    /**
     * Duplicate variable
     * @param {string} variableReference
     * @param {Variable} duplicateInfos
     * @returns {Promise<*>}
     */
    duplicateVariable(variableReference, duplicateInfos) {
        console.log("duplicate", variableReference)
        return call(`/catalog-api/v1/variables/${encodeURIComponent(variableReference)}/duplicate`, {
            responseFormat: 'json',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(duplicateInfos),
            action: {
                key: 'catalog.variable.actions.duplicate-variable',
                params: {
                    code: variableReference
                }
            }
        })
    }

}