<template>
    <v-menu>
        <template v-slot:activator="{ props }">
            <v-chip v-bind="props" size="large" :prepend-icon="current.icon" :color="current.color"
                    append-icon="mdi-chevron-down">
                <span>{{ current.label }}</span>
            </v-chip>
        </template>

        <v-list>
            <v-list-item v-for="state in Object.keys(states).filter(s => s !== modelValue)" @click="changeState(state)" :base-color="states[state].color">
                <v-icon class="mr-3" :color="states[state].color">{{ states[state].icon }}</v-icon>
                <span>{{ $t('common.' + states[state].action ) }}</span>
            </v-list-item>
        </v-list>
    </v-menu>
</template>
<script>
export default {
    name: 'StateSelector',
    props: {
        modelValue: String,
        collectionCode: String
    },
    data: function () {
        return {
            states: {
                ONLINE: {
                    label: this.$t('runner.collection.status.online'),
                    icon: 'mdi-arrow-up-bold-circle',
                    color: 'green-darken-1',
                    action: 'load'
                },
                OFFLINE: {
                    label: this.$t('runner.collection.status.offline'),
                    icon: 'mdi-arrow-down-bold-circle',
                    color: 'grey-darken-1',
                    action: 'unload'
                },
                // DISABLED: {
                //     label: this.$t('runner.collection.status.disabled'),
                //     icon: 'mdi-cancel',
                //     color: 'red-darken-3',
                //     action: 'disable'
                // }
            }
        }
    },
    computed: {
        current() {
            return this.states[this.modelValue]
        }
    },
    methods: {
        changeState(newState) {
            this.$root.$confirm(this.$t('runner.collection.status-to-' + newState.toLowerCase(), {code: this.collectionCode}), this.$t('runner.collection.confirm-status-to-' + newState.toLowerCase(), {code: this.collectionCode}))
              .then(confirm => {
                  if (confirm) {
                      this.$emit("changed", newState)
                  }
              });
        }
    }
}
</script>